import { useState, useEffect } from "react";
import NavOption from "./NavOption";

const MenuItem = ({
  setIsSandwichOpen,
  subnavigationk,
  item,
  isSandwichOpen,
  active,
  setActive,
}) => {
  const [subActive, setSubActive] = useState(item.path);
  const [isOpen, setIsOpen] = useState(active === item.path);
  useEffect(() => {
    if (!isOpen) {
      setSubActive(null);
    } else {
      setSubActive(item.path);
    }
  }, [isOpen]);

  return (
    <>
      <NavOption
        subnavigation={item.subnavigation}
        setIsSandwichOpen={setIsSandwichOpen}
        isSandwichOpen={isSandwichOpen}
        title={item.title}
        icon={item.icon}
        path={item.path}
        identifier={item.path}
        isSubNav={item.subNav?.length > 0}
        active={active}
        setActive={setActive}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen &&
        item.subNav?.map((subItem, index) => {
          return (
            <MenuItem
              isSandwichOpen={isSandwichOpen}
              key={index}
              item={subItem}
              active={subActive}
              setActive={setSubActive}
            />
          );
        })}
    </>
  );
};

export default MenuItem;
