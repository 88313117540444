import { useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Carousel } from "react-carousel-minimal";
import useApi from "../Hooks/useApi";
import * as api from "../api/getapi";
import { useEffect } from "react";
const DisplayAccordian = ({ data, index, id, open, setOpen }) => {
  const [images, setImages] = useState();
  const getImages = useApi(api.ImagesBycolor);

  const accordion = useRef();

  const fetchImages = async (e) => {
    try {
      setOpen(Number(accordion.current.getAttribute("customkey")));
      const res = await getImages.request(id, data.id);
      let img = res.data.map((i) => {
        return { image:process.env.REACT_APP_URL_S3 +"/"+ i }
      });
      setImages(img);
    } catch (err) {}
  };

  return (
    <>
      <Accordion.Item eventKey={index} onClick={fetchImages}>
        <Accordion.Header className="">
          <span
            ref={accordion}
            customkey={index}
            className="d-flex flex-row justify-content-between w-100"
          >
            <span style={{ color: "black", fontWeight: "550" }}>
              {data?.label || "Color"}
            </span>
          </span>
        </Accordion.Header>
        <Accordion.Body className="d-flex flex-column align-items-end">
          {images?.length > 0 ? (
            <>
              {open === index && (
                <Carousel
                  data={images}
                  width="850px"
                  height="400px"
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  automatic={false}
                  dots={true}
                  slideBackgroundColor="#f5f6fa"
                  slideImageFit="contain"
                  thumbnails={true}
                  thumbnailWidth="80px"
                  thumbnailsHeight="80px"
                  style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    maxHeight: "450px",
                    margin: "40px auto",
                  }}
                />
              )}
            </>
          ) : (
            <div className="col-3 ms-3 d-flex justify-content-end me-5">
              <div className="snippet" data-title="dot-flashing">
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div>
              </div>
            </div>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default DisplayAccordian;
