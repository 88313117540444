import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";

const ViewDesktopComputer = ({ id = -1 }) => {
  const navigate = useNavigate();

  const [desktop, setDesktop] = useState(null);

  useEffect(() => {
    async function getAccessory(id) {
      const dataRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/desktop-computers/" + id);
      setDesktop(dataRes.data.success.data);
    }
    if (id !== -1) {
      getAccessory(id);
    }
  }, [id]);

  return (
    <>
      {!desktop ? (
        <Loading />
      ) : (
        <div>
          <div style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
            <h3 className="px-5">
              {desktop.model_name}
              <hr className="" />
            </h3>
          </div>
          <Row>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Brand:</strong> {desktop.brand_name}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Title:</strong> {desktop.title}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Type:</strong> {desktop.desktop_type}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Colors:</strong>{" "}
              {desktop.colors_available.map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color.label}
                </span>
              ))}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Generation:</strong> {desktop.generation}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Processor:</strong> {desktop.processor}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>RAM:</strong> {`${desktop.ram} GB (${desktop.ram_type})`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Graphics:</strong>
              {`${desktop.graphic_card_type}`}
              <br />
              <strong>Graphic Card:</strong>
              {`${desktop.graphic_card_name} ${desktop.gcm_id === -1 ? "" : `(${desktop.graphic_card_memory} MB)`}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Storage:</strong>{" "}
              {`${desktop.storage_id === -1 ? "" : desktop.storage + "  GB (HDD)"}  
              ${desktop.storage_ssd_id === -1 || desktop.storage_id === -1 ? "" : " & "}
               ${desktop.storage_ssd_id === -1 ? "" : desktop.storage_ssd + "  GB (SSD)"}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Speaker:</strong> {desktop.speaker}
            </Col>
            {desktop.ss_id === -1 ? (
              ""
            ) : (
              <>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Screen Size:</strong> {desktop.screen_size + " inches"}
                </Col>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Refresh Rate:</strong> {desktop.refresh_rate + " Hz"}
                </Col>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Resolution:</strong> {desktop.resolution}
                </Col>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Screen Type:</strong> {desktop.screen_type}
                </Col>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Touch Screen:</strong> {desktop.touch_screen === 1 ? "Yes" : "No"}
                </Col>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Webcam:</strong> {desktop.webcam === 1 ? "Yes" : "No"}
                </Col>
              </>
            )}
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Ports:</strong>{" "}
              {desktop.ports.split(",").map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color}
                </span>
              ))}
            </Col>
            <Col md={12} lg={12} className="px-5 pb-2">
              <strong>Description:</strong>
              <div className="border rounded p-3" dangerouslySetInnerHTML={{ __html: desktop.description }} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ViewDesktopComputer;
