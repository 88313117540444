import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import Markdown from "../../Components/Markdown/Markdown";
import AddForm from "../../Components/AddForm";
import SubmitWaiting from "../../Components/SubmitWaiting/SubmitWaiting";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import PopUpModal from "../../Components/PopUpModal";
import AddComponent from "../../Components/Component/AddComponent";
import InputControl from "../../Components/InputFields/InputControl";
import DropdownControl from "../../Components/InputFields/DropdownControl";
import { dateTime } from "../../Features/dateTime";

const DesktopComputersFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, id } = useParams();

  const getComponentApi = useApi(api.getComponent);
  const componentApi = useApi(api.getComponentData);

  const [validated, setValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [modelName, setModelName] = useState("");
  const [launchPrice, setLaunchPrice] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [brand, setBrand] = useState("");
  const [color, setColor] = useState([]);
  const [storageType, setStorageType] = useState({
    value: "both",
    label: "Both",
  });
  const [storageSsd, setStorageSsd] = useState("");
  const [storage, setStorage] = useState("");
  const [desktopType, setDesktopType] = useState("");
  const [ram, setRam] = useState("");
  const [ramType, setRamType] = useState("");
  const [generation, setGeneration] = useState("");
  const [graphicCardName, setGraphicCardName] = useState("");
  const [graphicCardMemory, setGraphicCardMemory] = useState("");
  const [graphicCardType, setGraphicCardType] = useState("");
  const [processor, setProcessor] = useState("");
  const [ports, setPorts] = useState([]);
  const [speaker, setSpeaker] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [resolution, setResolution] = useState("");
  const [screenType, setScreenType] = useState("");
  const [refreshRate, setRefreshRate] = useState("");
  const [touchScreen, setTouchScreen] = useState({ value: false, label: "No" });
  const [webcam, setWebcam] = useState({ value: false, label: "No" });
  const [description, setDescription] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const [isAIO, setIsAIO] = useState(false);

  const [submiting, setSubmiting] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [failed, setFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [componentData, setComponentData] = useState(false);
  const [refresh, toggle] = useState(false);
  const [refreshDropdown, setRefreshDropdown] = useState("");
  const [isModalLoading, setIsModalLoading] = useState("");

  const getDropdownOptions = (component, filter) => {
    let timer = null;
    const comCall = (search) => {
      return componentApi.request(component, filter, true, undefined, undefined, search);
    };
    return (inputText) => {
      if (timer) {
        clearTimeout(timer);
      }
      return new Promise((resolve, reject) => {
        if (inputText.length > 0) {
          timer = setTimeout(() => {
            comCall(inputText)
              .then((data) => {
                const getData = data?.data?.success?.data.map(({ id, label }) => ({
                  value: id,
                  label: String(label),
                }));
                resolve(getData);
              })
              .catch((err) => {
                reject(err);
              });
          }, 200);
        } else {
          timer = null;
          comCall()
            .then((data) => {
              const getData = data?.data?.success?.data.map(({ id, label }) => ({
                value: id,
                label: String(label),
              }));
              resolve(getData);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    };
  };
  useEffect(() => {
    if (id) {
      axios.post(process.env.REACT_APP_URL_API_DATA + "/model/desktop-computers/" + id).then((res) => {
        if (res.data?.code === 200) {
          setErrorMessage("");
          const resData = res?.data?.success?.data;
          setTitle(resData.title);
          setModelName(resData.model_name);
          setBrand({
            value: resData.brand_id,
            label: resData.brand_name,
          });
          setLaunchPrice(resData.launch_price ? +resData.launch_price : "");
          if (resData.release_date) {
            let objectDate = dateTime(resData.release_date);
            let day = objectDate.getDate();
            let month = objectDate.getMonth() + 1;
            let year = objectDate.getFullYear();
            setReleaseDate(`${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`);
          }
          setColor(
            resData.colors_available.map(({ id, label }) => ({
              value: id,
              label: label,
            })),
          );
          if (+resData.storage_id !== -1 && +resData.storage_ssd_id !== -1) {
            setStorageType({ value: "both", label: "Both" });
          } else if (+resData.storage_id === -1 && +resData.storage_ssd_id !== -1) {
            setStorageType({ value: "ssd", label: "SSD" });
          } else if (+resData.storage_id !== -1 && +resData.storage_ssd_id === -1) {
            setStorageType({ value: "hdd", label: "HDD" });
          }
          setStorage(
            +resData.storage_id === -1
              ? null
              : {
                  value: resData.storage_id,
                  label: resData.storage,
                },
          );
          setStorageSsd(
            +resData.storage_ssd_id === -1
              ? null
              : {
                  value: resData.storage_ssd_id,
                  label: resData.storage_ssd,
                },
          );
          setRam({ value: resData.ram_id, label: resData.ram });
          setRamType({ value: resData.rt_id, label: resData.ram_type });
          setProcessor({ value: resData.p_id, label: resData.processor });
          setGeneration({
            value: resData.g_id,
            label: resData.generation,
          });
          setGraphicCardName({
            value: resData.gcn_id,
            label: resData.graphic_card_name,
          });
          setGraphicCardMemory(
            +resData.gcs_id === -1
              ? ""
              : {
                  value: resData.gcm_id,
                  label: resData.graphic_card_memory,
                },
          );
          setGraphicCardType({
            value: resData.gct_id,
            label: resData.graphic_card_type,
          });
          setDesktopType({
            value: resData.dt_id,
            label: resData.desktop_type,
          });

          setPorts(resData.ports.split(",").map((port) => ({ value: port, label: port })));
          setSpeaker({
            value: resData.sp_id,
            label: resData.speaker,
          });
          setIsAIO(+resData.ss_id !== -1);
          setScreenSize(
            +resData.ss_id === -1
              ? ""
              : {
                  value: resData.ss_id,
                  label: resData.screen_size,
                },
          );
          setResolution(
            +resData.rs_id === -1
              ? ""
              : {
                  value: resData.rs_id,
                  label: resData.resolution,
                },
          );
          setScreenType(
            +resData.st_id === -1
              ? ""
              : {
                  value: resData.st_id,
                  label: resData.screen_type,
                },
          );
          setRefreshRate(
            +resData.rr_id === -1
              ? ""
              : {
                  value: resData.rr_id,
                  label: resData.refresh_rate,
                },
          );
          setTouchScreen(
            +resData.touch_screen === 1
              ? {
                  value: true,
                  label: "Yes",
                }
              : {
                  value: false,
                  label: "No",
                },
          );
          setWebcam(
            +resData.webcam === 1
              ? {
                  value: true,
                  label: "Yes",
                }
              : {
                  value: false,
                  label: "No",
                },
          );
          setDescription(resData.description);
          setDescriptionText(resData.description);
        } else {
          setErrorMessage(res.data?.error?.message);
        }
      });
    }

    return () => {};
  }, [id]);

  useEffect(() => {
    setTitle(
      `${brand ? brand.label.trim() : ""} ${modelName.trim()} ${processor ? processor.label.trim() : ""} ${generation ? generation.label.trim() : ""} ${ram ? ram.label.trim() : ""}${
        storage && storageSsd ? `/(${storage ? storage.label.trim() : ""}&${storageSsd ? storageSsd.label.trim() : ""})` : storage ? " " + storage.label.trim() : storageSsd ? " " + storageSsd.label.trim() : ""
      }`,
    );
  }, [modelName, brand, processor, generation, ram, storage, storageSsd]);

  const callForComponentData = async (table_name) => {
    setIsModalLoading(true);
    setShowAddComponentModal(true);
    const tableComponentResponse = await getComponentApi.request(table_name);
    if (tableComponentResponse?.data?.code === 200) {
      setComponentData(tableComponentResponse?.data?.success?.data);
      setRefreshDropdown(table_name);
    }
    setIsModalLoading(false);
  };

  useEffect(() => {
    setRefreshDropdown("");
  }, [refresh]);

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
      let pass = true;
      if (isAIO) {
        if (Object.keys(screenSize).length > 0 && Object.keys(resolution).length > 0 && Object.keys(screenType).length > 0 && Object.keys(touchScreen).length > 0 && Object.keys(webcam).length > 0 && Object.keys(refreshRate).length > 0) {
          pass = true;
        } else {
          pass = false;
        }
      }
      let storageCheck = false;
      if (storageType.value === "ssd" && Object.keys(storageSsd).length > 0) {
        storageCheck = true;
      } else if (storageType.value === "hdd" && Object.keys(storage).length > 0) {
        storageCheck = true;
      } else if (storageType.value === "both" && Object.keys(storage).length > 0 && Object.keys(storageSsd).length > 0) {
        storageCheck = true;
      }

      let graphicCardCheck = false;
      if (graphicCardType.label !== "Integrated" && Object.keys(graphicCardMemory).length > 0) {
        graphicCardCheck = true;
      }
      if (graphicCardType.label === "Integrated") {
        graphicCardCheck = true;
      }

      if (
        title.trim().length > 0 &&
        modelName.trim().length > 0 &&
        launchPrice?.toString().length > 0 &&
        +launchPrice > 0 &&
        releaseDate &&
        descriptionText.trim().length > 0 &&
        Object.keys(brand).length > 0 &&
        Object.keys(color).length > 0 &&
        storageCheck &&
        Object.keys(ram).length > 0 &&
        Object.keys(ramType).length > 0 &&
        Object.keys(processor).length > 0 &&
        Object.keys(generation).length > 0 &&
        graphicCardCheck &&
        Object.keys(graphicCardName).length > 0 &&
        Object.keys(graphicCardType).length > 0 &&
        Object.keys(desktopType).length > 0 &&
        Object.keys(ports).length > 0 &&
        Object.keys(speaker).length > 0 &&
        pass
      ) {
        setSubmiting(true);
        const obj = {
          model_title: title.trim(),
          model_name: modelName.trim(),
          release_date: releaseDate,
          launch_price: launchPrice,
          category_id: 5,
          category_name: "Desktop Computer",
          brand_id: brand.value,
          brand_name: brand.label,
          colors_available: color.map((obj) => ({
            id: obj.value,
            label: obj.label,
          })),
          storage: storageType.value === "ssd" ? "nil" : storage.label.toString(),
          storage_id: storageType.value === "ssd" ? -1 : storage.value,
          storage_ssd_id: storageType.value === "hdd" ? -1 : storageSsd.value,
          storage_ssd: storageType.value === "hdd" ? "nil" : storageSsd.label.toString(),
          desktop_type: desktopType.label,
          dt_id: desktopType.value,
          ram: ram.label.toString(),
          ram_id: ram.value,
          ram_type: ramType.label,
          rt_id: ramType.value,
          generation: generation.label,
          g_id: generation.value,
          graphic_card_type: graphicCardType.label,
          gct_id: graphicCardType.value,
          graphic_card_name: graphicCardName.label,
          gcn_id: graphicCardName.value,
          graphic_card_memory: graphicCardType.label === "Integrated" ? "nil" : graphicCardMemory.label,
          gcm_id: graphicCardType.label === "Integrated" ? -1 : graphicCardMemory.value,
          processor: processor.label,
          p_id: processor.value,
          ports: ports.map((obj) => obj.label).join(","),
          speaker: speaker.label,
          sp_id: speaker.value,
          screen_size: screenSize.label,
          ss_id: screenSize.value,
          resolution: resolution.label,
          rs_id: resolution.value,
          screen_type: screenType.label,
          st_id: screenType.value,
          refresh_rate: refreshRate.label,
          rr_id: refreshRate.value,
          webcam: webcam.value,
          touch_screen: touchScreen.value,
          description: description,
        };
        let modelRes;
        if (mode === "edit" && id) {
          modelRes = await axios.patch(process.env.REACT_APP_URL_API_DATA + "/model/update/" + id, obj, { withCredentials: true });
        } else if (mode === "duplicate" && id) {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/duplicate/" + id, obj, { withCredentials: true });
        } else {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/add", obj, { withCredentials: true });
        }
        if (modelRes) {
          setSubmited(true);
          setTimeout(() => {
            setSubmiting(false);
            setSubmited(false);
            navigate("/desktop-computers?page=" + location.state.page);
          }, 2000);
        }
      }
    } catch (err) {
      setFailed(true);
      setTimeout(() => {
        setSubmiting(false);
        setFailed(false);
      }, 2000);
    }
  };

  return (
    <>
      <SubmitWaiting submiting={submiting} submited={submited} failed={failed} />
      <AddForm>
        <div className="d-flex justify-content-start">
          <button className="backFormButton" onClick={() => navigate(-1)}>
            <span>Back</span>
          </button>
        </div>
        <div className="d-flex justify-content-center mx-4 mb-2">
          <h2 className="text-secondary text-capitalize">{mode} Desktop Computers</h2>
        </div>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row>
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              label={"title"}
              type={"text"}
              placeholder={"Enter Title"}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              disabled={true}
              required
              feedback={{ type: "invalid", message: "Please Enter the title." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"text"}
              label={"Model Name"}
              placeholder={"Enter Model Name"}
              value={modelName}
              important={true}
              onChange={(e) => {
                setModelName(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Model name." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"number"}
              label={"Launch Price"}
              placeholder={"Enter Launch Price"}
              value={launchPrice}
              important={true}
              onChange={(e) => {
                const format = /^[0-9]*$/;
                const [first, second] = e.target.value.split(".");
                let endResult;
                let firstPart;
                if (format.test(first)) {
                  if (first?.length < 8) {
                    firstPart = first;
                  } else {
                    firstPart = first.slice(0, 8);
                  }
                }
                if (e.target.value.includes(".")) {
                  if (second?.length > 0) {
                    if (format.test(second)) {
                      if (second?.length < 3) {
                        endResult = [firstPart, second].join(".");
                      } else {
                        endResult = [firstPart, second.slice(0, 2)].join(".");
                      }
                    }
                  } else {
                    endResult = firstPart + ".";
                  }
                } else {
                  if (firstPart && format.test(firstPart)) {
                    endResult = firstPart;
                  } else {
                    endResult = "";
                  }
                }
                setLaunchPrice(endResult);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Launch Price." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"date"}
              label={"Release Date"}
              placeholder={"Enter Release Date"}
              value={releaseDate}
              important={true}
              onChange={(e) => {
                setReleaseDate(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Release Date." }}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"brand" === refreshDropdown ? refreshDropdown : "brand-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("brand")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Brand"}
              important={true}
              cache={true}
              options={getDropdownOptions("brand", {
                is_desktop: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setBrand(e);
              }}
              value={brand}
              required={true}
              feedback={{ type: "invalid", message: "Please select brand." }}
              isInvalid={validated && !brand}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"color" === refreshDropdown ? refreshDropdown : "color-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("color")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"color"}
              important={true}
              cache={true}
              options={getDropdownOptions("color", {
                is_desktop: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              closeMenuOnSelect={false}
              onChange={(e) => {
                setColor(e);
              }}
              value={color}
              isMulti={true}
              required={true}
              feedback={{ type: "invalid", message: "Please select colors." }}
              isInvalid={validated && !color.length}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              isSearchable={false}
              label={"Storage Type"}
              important={true}
              cache={true}
              options={() => {
                return new Promise((resolve, reject) => {
                  resolve([
                    {
                      value: "hdd",
                      label: "HDD",
                    },
                    {
                      value: "ssd",
                      label: "SSD",
                    },
                    {
                      value: "both",
                      label: "Both",
                    },
                  ]);
                });
              }}
              onChange={(e) => {
                if (e.value === "ssd") {
                  setStorage("");
                }
                if (e.value === "hdd") {
                  setStorageSsd("");
                }

                setStorageType(e);
              }}
              value={storageType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Storage Type." }}
              isInvalid={validated && !storageType}
            />

            {storageType.value === "hdd" || storageType.value === "both" ? (
              <DropdownControl
                as={Col}
                lg="4"
                md="6"
                sm="12"
                key={"hdd" === refreshDropdown ? refreshDropdown : "hdd-key"}
                noOptionsMessage={() => (
                  <div className="dropdown-no-option-action" onClick={() => callForComponentData("hdd")}>
                    Option is not available.
                    <br />
                    <span
                      style={{
                        color: "blue",
                        fontStyle: "italic",
                      }}
                    >
                      Click to Add
                    </span>
                  </div>
                )}
                label={"HDD(in GB)"}
                important={true}
                cache={true}
                options={getDropdownOptions("hdd", {
                  is_desktop: true,
                })}
                // onInputChange={(newInput) => {
                //   const inputValue = newInput.replace(/\W/g, "");
                //   return inputValue;
                // }}
                onChange={(e) => {
                  setStorage(e);
                }}
                value={storage}
                required={true}
                feedback={{ type: "invalid", message: "Please select HDD." }}
                isInvalid={validated && !storage}
              />
            ) : (
              ""
            )}

            {storageType.value === "ssd" || storageType.value === "both" ? (
              <DropdownControl
                as={Col}
                lg="4"
                md="6"
                sm="12"
                key={"ssd" === refreshDropdown ? refreshDropdown : "ssd-key"}
                noOptionsMessage={() => (
                  <div className="dropdown-no-option-action" onClick={() => callForComponentData("ssd")}>
                    Option is not available.
                    <br />
                    <span
                      style={{
                        color: "blue",
                        fontStyle: "italic",
                      }}
                    >
                      Click to Add
                    </span>
                  </div>
                )}
                label={"SSD(in GB)"}
                important={true}
                cache={true}
                options={getDropdownOptions("ssd", {
                  is_desktop: true,
                })}
                // onInputChange={(newInput) => {
                //   const inputValue = newInput.replace(/\W/g, "");
                //   return inputValue;
                // }}
                onChange={(e) => {
                  setStorageSsd(e);
                }}
                value={storageSsd}
                required={true}
                feedback={{ type: "invalid", message: "Please select SSD." }}
                isInvalid={validated && !storageSsd}
              />
            ) : (
              ""
            )}

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"ram" === refreshDropdown ? refreshDropdown : "ram-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("ram")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"RAM(in GB)"}
              important={true}
              cache={true}
              options={getDropdownOptions("ram", {
                is_desktop: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setRam(e);
              }}
              value={ram}
              required={true}
              feedback={{ type: "invalid", message: "Please select RAM." }}
              isInvalid={validated && !ram}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"ram_type" === refreshDropdown ? refreshDropdown : "ram_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("ram_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"RAM Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("ram_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setRamType(e);
              }}
              value={ramType}
              required={true}
              feedback={{ type: "invalid", message: "Please select RAM Type." }}
              isInvalid={validated && !ramType}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"processor" === refreshDropdown ? refreshDropdown : "processor-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("processor")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Processor"}
              important={true}
              cache={true}
              options={getDropdownOptions("processor", {
                is_desktop: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setProcessor(e);
              }}
              value={processor}
              required={true}
              feedback={{ type: "invalid", message: "Please select Processor." }}
              isInvalid={validated && !processor}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"generation" === refreshDropdown ? refreshDropdown : "generation-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("generation")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Generation"}
              important={true}
              cache={true}
              options={getDropdownOptions("generation", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setGeneration(e);
              }}
              value={generation}
              required={true}
              feedback={{ type: "invalid", message: "Please select Generation." }}
              isInvalid={validated && !generation}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"graphic_card_type" === refreshDropdown ? refreshDropdown : "graphic_card_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("graphic_card_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Graphic Card Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("graphic_card_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                if (e.label !== "Integrated") {
                  setGraphicCardMemory("");
                }
                setGraphicCardType(e);
              }}
              value={graphicCardType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Graphic Card Type." }}
              isInvalid={validated && !graphicCardType}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"graphic_card_name" === refreshDropdown ? refreshDropdown : "graphic_card_name-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("graphic_card_name")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Graphic Card Name"}
              important={true}
              cache={true}
              options={getDropdownOptions("graphic_card_name", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setGraphicCardName(e);
              }}
              value={graphicCardName}
              required={true}
              feedback={{ type: "invalid", message: "Please select Graphic Card Name." }}
              isInvalid={validated && !graphicCardName}
            />
            {graphicCardType.label !== "Integrated" ? (
              <DropdownControl
                as={Col}
                lg="4"
                md="6"
                sm="12"
                key={"graphic_card_memory" === refreshDropdown ? refreshDropdown : "graphic_card_memory-key"}
                noOptionsMessage={() => (
                  <div className="dropdown-no-option-action" onClick={() => callForComponentData("graphic_card_memory")}>
                    Option is not available.
                    <br />
                    <span
                      style={{
                        color: "blue",
                        fontStyle: "italic",
                      }}
                    >
                      Click to Add
                    </span>
                  </div>
                )}
                label={"Graphic Card Memory(in MB)"}
                important={true}
                cache={true}
                options={getDropdownOptions("graphic_card_memory", {})}
                // onInputChange={(newInput) => {
                //   const inputValue = newInput.replace(/\W/g, "");
                //   return inputValue;
                // }}
                onChange={(e) => {
                  setGraphicCardMemory(e);
                }}
                value={graphicCardMemory}
                required={true}
                feedback={{ type: "invalid", message: "Please select Graphic Card Memory." }}
                isInvalid={validated && !graphicCardMemory}
              />
            ) : (
              ""
            )}
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"desktop_type" === refreshDropdown ? refreshDropdown : "desktop_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("desktop_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Desktop Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("desktop_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setDesktopType(e);
              }}
              value={desktopType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Laptop Type." }}
              isInvalid={validated && !desktopType}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"ports" === refreshDropdown ? refreshDropdown : "ports-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("ports")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Ports"}
              important={true}
              cache={true}
              options={getDropdownOptions("ports", {
                is_desktop: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setPorts(e);
              }}
              value={ports}
              isMulti={true}
              closeMenuOnSelect={false}
              required={true}
              feedback={{ type: "invalid", message: "Please select Ports." }}
              isInvalid={validated && !ports.length}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"speaker" === refreshDropdown ? refreshDropdown : "speaker-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("speaker")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Speaker"}
              important={true}
              cache={true}
              options={getDropdownOptions("speaker", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setSpeaker(e);
              }}
              value={speaker}
              required={true}
              feedback={{ type: "invalid", message: "Please select Speaker." }}
              isInvalid={validated && !speaker}
            />

            <Form.Group className="my-2" as={Col} lg="12" md="12" sm="12" controlId="formBasicShopName">
              <label>
                Is All in One System? <span className="text-danger"></span>
              </label>
              <Form.Check
                type="switch"
                id={`default-`}
                name="isAIOEnable"
                value={isAIO}
                // disabled={editData?.is_filters === 1 ? true : false}
                checked={isAIO}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsAIO(true);
                  } else {
                    setIsAIO(false);
                    setScreenSize("");
                    setResolution("");
                    setScreenType("");
                    setRefreshRate("");
                    setTouchScreen({ value: false, label: "No" });
                    setWebcam({ value: false, label: "No" });
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">* Please Enter component Name</Form.Control.Feedback>
            </Form.Group>
            {isAIO && (
              <>
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  key={"screen_size" === refreshDropdown ? refreshDropdown : "screen_size-key"}
                  noOptionsMessage={() => (
                    <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_size")}>
                      Option is not available.
                      <br />
                      <span
                        style={{
                          color: "blue",
                          fontStyle: "italic",
                        }}
                      >
                        Click to Add
                      </span>
                    </div>
                  )}
                  label={"Screen Size(in inches)"}
                  important={true}
                  cache={true}
                  options={getDropdownOptions("screen_size", { is_desktop: true })}
                  // onInputChange={(newInput) => {
                  //   const inputValue = newInput.replace(/\W/g, "");
                  //   return inputValue;
                  // }}
                  onChange={(e) => {
                    setScreenSize(e);
                  }}
                  value={screenSize}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Screen Size." }}
                  isInvalid={validated && !screenSize}
                />
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  key={"resolution" === refreshDropdown ? refreshDropdown : "resolution-key"}
                  noOptionsMessage={() => (
                    <div className="dropdown-no-option-action" onClick={() => callForComponentData("resolution")}>
                      Option is not available.
                      <br />
                      <span
                        style={{
                          color: "blue",
                          fontStyle: "italic",
                        }}
                      >
                        Click to Add
                      </span>
                    </div>
                  )}
                  label={"Screen Resolution(in Pixels)"}
                  important={true}
                  cache={true}
                  options={getDropdownOptions("resolution", {})}
                  // onInputChange={(newInput) => {
                  //   const inputValue = newInput.replace(/\W/g, "");
                  //   return inputValue;
                  // }}
                  onChange={(e) => {
                    setResolution(e);
                  }}
                  value={resolution}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Screen Resolution." }}
                  isInvalid={validated && !resolution}
                />
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  key={"screen_type" === refreshDropdown ? refreshDropdown : "screen_type-key"}
                  noOptionsMessage={() => (
                    <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_type")}>
                      Option is not available.
                      <br />
                      <span
                        style={{
                          color: "blue",
                          fontStyle: "italic",
                        }}
                      >
                        Click to Add
                      </span>
                    </div>
                  )}
                  label={"Screen Type"}
                  important={true}
                  cache={true}
                  options={getDropdownOptions("screen_type", {})}
                  // onInputChange={(newInput) => {
                  //   const inputValue = newInput.replace(/\W/g, "");
                  //   return inputValue;
                  // }}
                  onChange={(e) => {
                    setScreenType(e);
                  }}
                  value={screenType}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Screen Type." }}
                  isInvalid={validated && !screenType}
                />
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  key={"refresh_rate" === refreshDropdown ? refreshDropdown : "refresh_rate-key"}
                  noOptionsMessage={() => (
                    <div className="dropdown-no-option-action" onClick={() => callForComponentData("refresh_rate")}>
                      Option is not available.
                      <br />
                      <span
                        style={{
                          color: "blue",
                          fontStyle: "italic",
                        }}
                      >
                        Click to Add
                      </span>
                    </div>
                  )}
                  label={"Refresh Rate(in Hz)"}
                  important={true}
                  cache={true}
                  options={getDropdownOptions("refresh_rate", {})}
                  // onInputChange={(newInput) => {
                  //   const inputValue = newInput.replace(/\W/g, "");
                  //   return inputValue;
                  // }}
                  onChange={(e) => {
                    setRefreshRate(e);
                  }}
                  value={refreshRate}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Refresh Rate." }}
                  isInvalid={validated && !refreshRate}
                />

                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  isSearchable={false}
                  label={"Touch Screen"}
                  important={true}
                  cache={true}
                  options={() => {
                    return new Promise((resolve, reject) => {
                      resolve([
                        {
                          value: true,
                          label: "Yes",
                        },
                        {
                          value: false,
                          label: "No",
                        },
                      ]);
                    });
                  }}
                  onChange={(e) => {
                    setTouchScreen(e);
                  }}
                  value={touchScreen}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Touch Screen." }}
                  isInvalid={validated && !touchScreen}
                />
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  isSearchable={false}
                  label={"Webcam"}
                  important={true}
                  cache={true}
                  options={() => {
                    return new Promise((resolve, reject) => {
                      resolve([
                        {
                          value: true,
                          label: "Yes",
                        },
                        {
                          value: false,
                          label: "No",
                        },
                      ]);
                    });
                  }}
                  onChange={(e) => {
                    setWebcam(e);
                  }}
                  value={webcam}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Webcam." }}
                  isInvalid={validated && !webcam}
                />
              </>
            )}

            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="description">
              <Form.Label className="asterik-label">Description:</Form.Label>
              <Markdown value={description} setValue={setDescription} setText={setDescriptionText} placeholder="Enter Description (5,000 Characters)" />
              <Form.Control
                hidden
                as="textarea"
                placeholder="Enter Description (5,000 Characters)"
                value={descriptionText.trim()}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxLength={5000}
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter the description.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="submit-button">
              <Form.Control type="button" value={mode === "edit" ? "Edit" : "Add"} onClick={submitForm} className="btn btn-primary text-capitalize" />
            </Form.Group>
          </Row>
        </Form>
      </AddForm>
      <PopUpModal size="md" title={`Add ${componentData.name}`} show={showAddComponentModal} onHide={() => setShowAddComponentModal(false)} isLoading={isModalLoading}>
        <AddComponent close={() => setShowAddComponentModal(false)} toggle={toggle} fieldData={componentData} />
      </PopUpModal>
    </>
  );
};

export default DesktopComputersFormPage;
