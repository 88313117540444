import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_DATA,
  // baseURL: "http://192.168.3.82:5010",
});
const imageApi = axios.create({
  baseURL: process.env.REACT_APP_URL_IMAGE_CDN,
});

const config = {
  headers: {
    Authorization: localStorage.getItem("refreshToken"),
  },
};

//Component---------- START -------------

export function addComponent(table, data) {
  return api.post(
    `/component/${table}`,
    { component: data },
    { withCredentials: true }
  );
}

export function updateComponent(table, id, data) {
  return api.patch(
    `/component/${table}/${id}`,
    { component: data },
    { withCredentials: true }
  );
}

// Component---------- END---------------

// Model Images -------------------------
export function updateImage(id, formData) {
  return imageApi.post(`/data/${id}`, formData, { withCredentials: true });
}

// Model Images -----------END--------------


export function uploadBrandLogo(formData) {
  return imageApi.post(`/brand`, formData, { withCredentials: true });
}