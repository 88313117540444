import React from "react";
import style from "./Markdown.module.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

const Markdown = ({ value, setValue, setText, placeholder }) => {
  const textProp = {
    placeholder,
  };

  return (
    <>
      <div className={`${style.field}`}>
        <ReactQuill
          placeholder="Add Description here..."
          theme="snow"
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike", "blockquote", "code-block", { script: "sub" }, { script: "super" }, "link"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                [{ align: "center" }, { align: "" }, { align: "right" }, { align: "justify" }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ size: ["8px", "10px", "12px", "14px", "18px", "24px", "36px"] }, "line-height"],
                ["clean"],
              ],
            },
          }}
          value={value}
          onChange={(val, delta, _, content) => {
            setValue(val);
            setText(content.getText());
          }}
        />
        {/* <Editor
          apiKey="v1oaw3yn9un79c63uodd74j2ecckl6kpejp444c7divbpm6m"
          onEditorChange={(val, editor) => {
            setValue(val);
            setText(editor.getContent({ format: "text" }));
          }}
          value={value}
          init={{
            placeholder: "Add Description here...",
            height: 300,
            plugins: ["fullscreen", "wordcount", "table"],
            toolbar: "undo redo | styles | " + "bold italic backcolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | fullscreen",
          }}
        /> */}
      </div>
    </>
  );
};

export default Markdown;
