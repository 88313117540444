import React from "react";
import { Link } from "react-router-dom";

function ModelFilter({
  title,
  addButton = {
    title: "Add",
    link: "",
    page: 1,
  },
  search = {
    value: "",
    setValue: () => {},
  },
}) {
  return (
    <>
      <div className="d-flex justify-content-center mx-4 mb-2">
        <h2 className="text-secondary">{title}</h2>
      </div>
      <div className="d-flex justify-content-between align-items-center  mx-4">
        <input type="search" placeholder="Search" className="px-3 rounded" value={search.value} onChange={search.setValue} />
        <Link to={addButton.link} state={{ page: addButton.page }}>
          <button>{addButton.title}</button>
        </Link>
      </div>
    </>
  );
}

export default ModelFilter;
