import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";

const ViewAccessories = ({ id = -1 }) => {
  const navigate = useNavigate();

  const [accessory, setAccessory] = useState(null);

  useEffect(() => {
    async function getAccessory(id) {
      const dataRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/accessories/" + id);
      setAccessory(dataRes.data.success.data);
    }
    if (id !== -1) {
      getAccessory(id);
    }
  }, [id]);

  return (
    <>
      {!accessory ? (
        <Loading />
      ) : (
        <div>
          <div className="text-center" style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
            <h3 className="px-5">
              {accessory.model_name}
              <hr className="" />
            </h3>
          </div>
          <Row>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Brand:</strong> {accessory.brand_name}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Title:</strong> {accessory.title}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Type:</strong> {accessory.accessory_type}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Colors:</strong>{" "}
              {accessory.colors_available.map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color.label}
                </span>
              ))}
            </Col>
            <Col md={12} lg={12} className="px-5 pb-2">
              <strong>Description:</strong>
              <div className="border rounded p-3" dangerouslySetInnerHTML={{ __html: accessory.description }} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ViewAccessories;
