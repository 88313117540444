import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import Markdown from "../../Components/Markdown/Markdown";
import AddForm from "../../Components/AddForm";
import SubmitWaiting from "../../Components/SubmitWaiting/SubmitWaiting";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import PopUpModal from "../../Components/PopUpModal";
import AddComponent from "../../Components/Component/AddComponent";
import InputControl from "../../Components/InputFields/InputControl";
import DropdownControl from "../../Components/InputFields/DropdownControl";
import { dateTime } from "../../Features/dateTime";

const MobilesFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, id } = useParams();

  const getComponentApi = useApi(api.getComponent);
  const componentApi = useApi(api.getComponentData);

  const [validated, setValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [modelName, setModelName] = useState("");
  const [launchPrice, setLaunchPrice] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [brand, setBrand] = useState("");
  const [color, setColor] = useState([]);
  const [storage, setStorage] = useState("");
  const [screenType, setScreenType] = useState("");
  const [ram, setRam] = useState("");
  const [processor, setProcessor] = useState("");
  const [ports, setPorts] = useState("");
  const [speaker, setSpeaker] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [refreshRate, setRefreshRate] = useState("");
  const [resolution, setResolution] = useState("");
  const [camera, setCamera] = useState("");
  const [simType, setSimType] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [fingerPrint, setFingerPrint] = useState("");
  const [battery, setBattery] = useState("");
  const [networkBands, setNetworkBands] = useState("");
  const [screenProtection, setScreenProtection] = useState("");
  const [eSim, setESim] = useState({ value: false, label: "No" });
  const [description, setDescription] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const [submiting, setSubmiting] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [failed, setFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [componentData, setComponentData] = useState(false);
  const [refresh, toggle] = useState(false);
  const [refreshDropdown, setRefreshDropdown] = useState("");
  const [isModalLoading, setIsModalLoading] = useState("");

  const getDropdownOptions = (component, filter) => {
    let timer = null;
    const comCall = (search) => {
      return componentApi.request(component, filter, true, undefined, undefined, search);
    };
    return (inputText) => {
      if (timer) {
        clearTimeout(timer);
      }
      return new Promise((resolve, reject) => {
        if (inputText.length > 0) {
          timer = setTimeout(() => {
            comCall(inputText)
              .then((data) => {
                const getData = data?.data?.success?.data.map(({ id, label }) => ({
                  value: id,
                  label: String(label),
                }));
                resolve(getData);
              })
              .catch((err) => {
                reject(err);
              });
          }, 200);
        } else {
          timer = null;
          comCall()
            .then((data) => {
              const getData = data?.data?.success?.data.map(({ id, label }) => ({
                value: id,
                label: String(label),
              }));
              resolve(getData);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    };
  };
  useEffect(() => {
    if (id) {
      axios.post(process.env.REACT_APP_URL_API_DATA + "/model/mobiles/" + id).then((res) => {
        if (res.data?.code === 200) {
          setErrorMessage("");
          const resData = res?.data?.success?.data;
          setTitle(resData.title);
          setModelName(resData.model_name);
          setBrand({
            value: resData.brand_id,
            label: resData.brand_name,
          });
          setLaunchPrice(resData.launch_price ? +resData.launch_price : "");
          if (resData.release_date) {
            let objectDate = dateTime(resData.release_date);
            let day = objectDate.getDate();
            let month = objectDate.getMonth() + 1;
            let year = objectDate.getFullYear();
            setReleaseDate(`${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`);
          }
          setColor(
            resData.colors_available.map(({ id, label }) => ({
              value: id,
              label: label,
            })),
          );
          setStorage({
            value: resData.storage_id,
            label: resData.storage,
          });
          setRam({ value: resData.ram_id, label: resData.ram });
          setProcessor({ value: resData.p_id, label: resData.processor });
          setPorts({ value: resData.po_id, label: resData.ports });
          setSpeaker({ value: resData.sp_id, label: resData.speaker });
          setResolution({
            value: resData.rs_id,
            label: resData.resolution,
          });
          setRefreshRate({
            value: resData.rr_id,
            label: resData.refresh_rate,
          });
          setScreenSize({
            value: resData.ss_id,
            label: resData.screen_size,
          });
          setScreenType({
            value: resData.st_id,
            label: resData.screen_type,
          });
          setCamera({
            value: resData.cs_id,
            label: resData.camera_specs,
          });
          setSimType({
            value: resData.s_t_id,
            label: resData.sim_type,
          });
          setBodyType({
            value: resData.b_t_id,
            label: resData.body_type,
          });
          setFingerPrint({
            value: resData.fp_id,
            label: resData.finger_print,
          });
          setBattery({
            value: resData.bc_id,
            label: resData.battery_capacity,
          });
          setNetworkBands({
            value: resData.nb_id,
            label: resData.network_bands,
          });
          setScreenProtection({
            value: resData.s_p_id,
            label: resData.screen_protection,
          });
          setESim(
            +resData.e_sim === 1
              ? {
                  value: true,
                  label: "Yes",
                }
              : {
                  value: false,
                  label: "No",
                },
          );
          setDescription(resData.description);
          setDescriptionText(resData.description);
        } else {
          setErrorMessage(res.data?.error?.message);
        }
      });
    }

    return () => {};
  }, [id]);

  useEffect(() => {
    setTitle(`${brand ? brand.label.trim() : ""} ${modelName.trim()} ${ram ? ram.label.trim() : ""}/${storage ? storage.label.trim() : ""}`);
  }, [modelName, brand, ram, storage]);

  const callForComponentData = async (table_name) => {
    setIsModalLoading(true);
    setShowAddComponentModal(true);
    const tableComponentResponse = await getComponentApi.request(table_name);
    if (tableComponentResponse?.data?.code === 200) {
      setComponentData(tableComponentResponse?.data?.success?.data);
      setRefreshDropdown(table_name);
    }
    setIsModalLoading(false);
  };

  useEffect(() => {
    setRefreshDropdown("");
  }, [refresh]);

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);

      if (
        title.trim().length > 0 &&
        modelName.trim().length > 0 &&
        launchPrice?.toString().length > 0 &&
        +launchPrice > 0 &&
        releaseDate &&
        descriptionText.trim().length > 0 &&
        Object.keys(brand).length > 0 &&
        Object.keys(color).length > 0 &&
        Object.keys(storage).length > 0 &&
        Object.keys(ram).length > 0 &&
        Object.keys(processor).length > 0 &&
        Object.keys(ports).length > 0 &&
        Object.keys(speaker).length > 0 &&
        Object.keys(resolution).length > 0 &&
        Object.keys(refreshRate).length > 0 &&
        Object.keys(screenSize).length > 0 &&
        Object.keys(screenType).length > 0 &&
        Object.keys(camera).length > 0 &&
        Object.keys(simType).length > 0 &&
        Object.keys(bodyType).length > 0 &&
        Object.keys(fingerPrint).length > 0 &&
        Object.keys(battery).length > 0 &&
        Object.keys(networkBands).length > 0 &&
        Object.keys(screenProtection).length > 0 &&
        Object.keys(eSim).length > 0
      ) {
        setSubmiting(true);
        const obj = {
          model_title: title.trim(),
          model_name: modelName.trim(),
          release_date: releaseDate,
          launch_price: launchPrice,
          category_id: 2,
          category_name: "Mobile",
          brand_id: brand.value,
          brand_name: brand.label,
          colors_available: color.map((obj) => ({
            id: obj.value,
            label: obj.label,
          })),
          mobile_storage: storage.label.toString(),
          mobile_storage_id: storage.value,
          ram: ram.label.toString(),
          ram_id: ram.value,
          processor: processor.label,
          p_id: processor.value,
          screen_size: screenSize.label,
          ss_id: screenSize.value,
          ports: ports.label,
          po_id: ports.value,
          speaker: speaker.label,
          sp_id: speaker.value,
          screen_type: screenType.label,
          st_id: screenType.value,
          refresh_rate: refreshRate.label,
          rr_id: refreshRate.value,
          resolution: resolution.label,
          rs_id: resolution.value,
          camera_specs: camera.label,
          cs_id: camera.value,
          sim_type: simType.label,
          s_t_id: simType.value,
          body_type: bodyType.label,
          b_t_id: bodyType.value,
          finger_print: fingerPrint.label,
          fp_id: fingerPrint.value,
          battery_capacity: battery.label,
          bc_id: battery.value,
          network_bands: networkBands.label,
          nb_id: networkBands.value,
          screen_protection: screenProtection.label,
          s_p_id: screenProtection.value,
          e_sim: eSim.value,
          description: description,
        };
        let modelRes;
        if (mode === "edit" && id) {
          modelRes = await axios.patch(process.env.REACT_APP_URL_API_DATA + "/model/update/" + id, obj, { withCredentials: true });
        } else if (mode === "duplicate" && id) {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/duplicate/" + id, obj, { withCredentials: true });
        } else {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/add", obj, { withCredentials: true });
        }
        if (modelRes) {
          setSubmited(true);
          setTimeout(() => {
            setSubmiting(false);
            setSubmited(false);
            navigate("/mobiles?page=" + location.state.page);
          }, 2000);
        }
      }
    } catch (err) {
      setFailed(true);
      setTimeout(() => {
        setSubmiting(false);
        setFailed(false);
      }, 2000);
    }
  };

  return (
    <>
      <SubmitWaiting submiting={submiting} submited={submited} failed={failed} />
      <AddForm>
        <div className="d-flex justify-content-start">
          <button className="backFormButton" onClick={() => navigate(-1)}>
            <span>Back</span>
          </button>
        </div>
        <div className="d-flex justify-content-center mx-4 mb-2">
          <h2 className="text-secondary text-capitalize">{mode} Mobiles</h2>
        </div>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row>
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              label={"title"}
              type={"text"}
              placeholder={"Enter Title"}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              disabled={true}
              required
              feedback={{ type: "invalid", message: "Please Enter the title." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"text"}
              label={"Model Name"}
              placeholder={"Enter Model Name"}
              value={modelName}
              important={true}
              onChange={(e) => {
                setModelName(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Model name." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"number"}
              label={"Launch Price"}
              placeholder={"Enter Launch Price"}
              value={launchPrice}
              important={true}
              onChange={(e) => {
                const format = /^[0-9]*$/;
                const [first, second] = e.target.value.split(".");
                let endResult;
                let firstPart;
                if (format.test(first)) {
                  if (first?.length < 8) {
                    firstPart = first;
                  } else {
                    firstPart = first.slice(0, 8);
                  }
                }
                if (e.target.value.includes(".")) {
                  if (second?.length > 0) {
                    if (format.test(second)) {
                      if (second?.length < 3) {
                        endResult = [firstPart, second].join(".");
                      } else {
                        endResult = [firstPart, second.slice(0, 2)].join(".");
                      }
                    }
                  } else {
                    endResult = firstPart + ".";
                  }
                } else {
                  if (firstPart && format.test(firstPart)) {
                    endResult = firstPart;
                  } else {
                    endResult = "";
                  }
                }
                setLaunchPrice(endResult);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Launch Price." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"date"}
              label={"Release Date"}
              placeholder={"Enter Release Date"}
              value={releaseDate}
              important={true}
              onChange={(e) => {
                setReleaseDate(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Release Date." }}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"brand" === refreshDropdown ? refreshDropdown : "brand-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("brand")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Brand"}
              important={true}
              cache={true}
              options={getDropdownOptions("brand", {
                is_mobile: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setBrand(e);
              }}
              value={brand}
              required={true}
              feedback={{ type: "invalid", message: "Please select brand." }}
              isInvalid={validated && !brand}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"color" === refreshDropdown ? refreshDropdown : "color-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("color")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"color"}
              important={true}
              cache={true}
              options={getDropdownOptions("color", {
                is_mobile: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              closeMenuOnSelect={false}
              onChange={(e) => {
                setColor(e);
              }}
              value={color}
              isMulti={true}
              required={true}
              feedback={{ type: "invalid", message: "Please select colors." }}
              isInvalid={validated && !color.length}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"mobile_storage" === refreshDropdown ? refreshDropdown : "mobile_storage-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("mobile_storage")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Storage(in GB)"}
              important={true}
              cache={true}
              options={getDropdownOptions("mobile_storage", {
                is_mobile: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setStorage(e);
              }}
              value={storage}
              required={true}
              feedback={{ type: "invalid", message: "Please select Storage." }}
              isInvalid={validated && !storage}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"ram" === refreshDropdown ? refreshDropdown : "ram-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("ram")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"RAM(in GB)"}
              important={true}
              cache={true}
              options={getDropdownOptions("ram", {
                is_mobile: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setRam(e);
              }}
              value={ram}
              required={true}
              feedback={{ type: "invalid", message: "Please select RAM." }}
              isInvalid={validated && !ram}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"processor" === refreshDropdown ? refreshDropdown : "processor-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("processor")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Processor"}
              important={true}
              cache={true}
              options={getDropdownOptions("processor", {
                is_mobile: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setProcessor(e);
              }}
              value={processor}
              required={true}
              feedback={{ type: "invalid", message: "Please select Processor." }}
              isInvalid={validated && !processor}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"ports" === refreshDropdown ? refreshDropdown : "ports-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("ports")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Port"}
              important={true}
              cache={true}
              options={getDropdownOptions("ports", {
                is_mobile: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setPorts(e);
              }}
              value={ports}
              required={true}
              feedback={{ type: "invalid", message: "Please select Port." }}
              isInvalid={validated && !ports}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"speaker" === refreshDropdown ? refreshDropdown : "speaker-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("speaker")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Speaker"}
              important={true}
              cache={true}
              options={getDropdownOptions("speaker", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setSpeaker(e);
              }}
              value={speaker}
              required={true}
              feedback={{ type: "invalid", message: "Please select Speaker." }}
              isInvalid={validated && !speaker}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"screen_size" === refreshDropdown ? refreshDropdown : "screen_size-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_size")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Size(in inches)"}
              important={true}
              cache={true}
              options={getDropdownOptions("screen_size", { is_mobile: true })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setScreenSize(e);
              }}
              value={screenSize}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Size." }}
              isInvalid={validated && !screenSize}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"resolution" === refreshDropdown ? refreshDropdown : "resolution-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("resolution")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Resolution(in Pixels)"}
              important={true}
              cache={true}
              options={getDropdownOptions("resolution", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setResolution(e);
              }}
              value={resolution}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Resolution." }}
              isInvalid={validated && !resolution}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"screen_type" === refreshDropdown ? refreshDropdown : "screen_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("screen_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setScreenType(e);
              }}
              value={screenType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Type." }}
              isInvalid={validated && !screenType}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"refresh_rate" === refreshDropdown ? refreshDropdown : "refresh_rate-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("refresh_rate")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Refresh Rate(in Hz)"}
              important={true}
              cache={true}
              options={getDropdownOptions("refresh_rate", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setRefreshRate(e);
              }}
              value={refreshRate}
              required={true}
              feedback={{ type: "invalid", message: "Please select Refresh Rate." }}
              isInvalid={validated && !refreshRate}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"camera_specs" === refreshDropdown ? refreshDropdown : "camera_specs-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("camera_specs")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Camera(in MP)"}
              important={true}
              cache={true}
              options={getDropdownOptions("camera_specs", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setCamera(e);
              }}
              value={camera}
              required={true}
              feedback={{ type: "invalid", message: "Please select Camera." }}
              isInvalid={validated && !camera}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"sim_type" === refreshDropdown ? refreshDropdown : "sim_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("sim_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"SIM Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("sim_type", { is_mobile: true })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setSimType(e);
              }}
              value={simType}
              required={true}
              feedback={{ type: "invalid", message: "Please select SIM Type." }}
              isInvalid={validated && !simType}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"body_type" === refreshDropdown ? refreshDropdown : "body_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("body_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Body Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("body_type", { is_mobile: true })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setBodyType(e);
              }}
              value={bodyType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Body Type." }}
              isInvalid={validated && !bodyType}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"finger_print" === refreshDropdown ? refreshDropdown : "finger_print-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("finger_print")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Finger Print"}
              important={true}
              cache={true}
              options={getDropdownOptions("finger_print", { is_mobile: true })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setFingerPrint(e);
              }}
              value={fingerPrint}
              required={true}
              feedback={{ type: "invalid", message: "Please select Finger Print." }}
              isInvalid={validated && !fingerPrint}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"battery_capacity" === refreshDropdown ? refreshDropdown : "battery_capacity-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("battery_capacity")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Battery Capacity (in mAh)"}
              important={true}
              cache={true}
              options={getDropdownOptions("battery_capacity", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setBattery(e);
              }}
              value={battery}
              required={true}
              feedback={{ type: "invalid", message: "Please select Battery Capacity." }}
              isInvalid={validated && !battery}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"network_bands" === refreshDropdown ? refreshDropdown : "network_bands-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("network_bands")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Network Bands"}
              important={true}
              cache={true}
              options={getDropdownOptions("network_bands", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setNetworkBands(e);
              }}
              value={networkBands}
              required={true}
              feedback={{ type: "invalid", message: "Please select Network Bands." }}
              isInvalid={validated && !networkBands}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"screen_protection" === refreshDropdown ? refreshDropdown : "screen_protection-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_protection")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Protection"}
              important={true}
              cache={true}
              options={getDropdownOptions("screen_protection", { is_mobile: true })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setScreenProtection(e);
              }}
              value={screenProtection}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Protection." }}
              isInvalid={validated && !screenProtection}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              isSearchable={false}
              label={"E-Sim"}
              important={true}
              cache={true}
              options={() => {
                return new Promise((resolve, reject) => {
                  resolve([
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]);
                });
              }}
              onChange={(e) => {
                setESim(e);
              }}
              value={eSim}
              required={true}
              feedback={{ type: "invalid", message: "Please select E-Sim." }}
              isInvalid={validated && !eSim}
            />

            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="description">
              <Form.Label className="asterik-label">Description:</Form.Label>
              <Markdown value={description} setValue={setDescription} setText={setDescriptionText} placeholder="Enter Description (5,000 Characters)" />
              <Form.Control
                hidden
                as="textarea"
                placeholder="Enter Description (5,000 Characters)"
                value={descriptionText.trim()}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxLength={5000}
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter the description.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="submit-button">
              <Form.Control type="button" value={mode === "edit" ? "Edit" : "Add"} onClick={submitForm} className="btn btn-primary text-capitalize" />
            </Form.Group>
          </Row>
        </Form>
      </AddForm>
      <PopUpModal size="md" title={`Add ${componentData.name}`} show={showAddComponentModal} onHide={() => setShowAddComponentModal(false)} isLoading={isModalLoading}>
        <AddComponent close={() => setShowAddComponentModal(false)} toggle={toggle} fieldData={componentData} />
      </PopUpModal>
    </>
  );
};

export default MobilesFormPage;
