import React,{useState,useEffect,useRef} from 'react';
import { Link,Outlet,useNavigate,useParams  } from 'react-router-dom';
import PaginationBar from '../../Components/PaginationBar/PaginationBar';


//New Implementation----------------Start-----------------
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import Loading from '../../Components/Loading/Loading';
import NotFound from '../../Components/404';
import ComponentHomePageView from '../../Components/Component/Component';
import ComponentsList from './ComponentsList';


//New Implementation-----------------End------------------

const ComponentHomePage = () => {
  const { component } = useParams();
  const componentApi = useApi(api.getAllComponent);

  const [componentData,setComponentData] = useState(null)

  useEffect(() => {
    async function getComponent(){
        try{
            const res = await componentApi.request(component);
            if(res){
                setComponentData(res.data.success.data)
            }
        }catch(error){
        }
    }
    getComponent()
  }, [component]);
    return ( <>
    {
        component?
        <Outlet />:
        <ComponentsList
        components={componentData}
        />
    }
    </> );
}
 
export default ComponentHomePage;