import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";

const ViewLaptop = ({ id = -1 }) => {
  const navigate = useNavigate();

  const [laptop, setLaptop] = useState(null);

  useEffect(() => {
    async function getAccessory(id) {
      const dataRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/laptops/" + id);
      setLaptop(dataRes.data.success.data);
    }
    if (id !== -1) {
      getAccessory(id);
    }
  }, [id]);

  return (
    <>
      {!laptop ? (
        <Loading />
      ) : (
        <div>
          <div style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
            <h3 className="px-5">
              {laptop.model_name}
              <hr className="" />
            </h3>
          </div>
          <Row>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Brand:</strong> {laptop.brand_name}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Title:</strong> {laptop.title}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Type:</strong> {laptop.laptop_type}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Colors:</strong>{" "}
              {laptop.colors_available.map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm text-capitalize">
                  {color?.label}
                </span>
              ))}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Processor:</strong> {laptop.processor}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Generation:</strong> {laptop.generation}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Graphics:</strong>
              {`${laptop.graphic_card_type}`}
              <br />
              <strong>Graphic Card:</strong>
              {`${laptop.graphic_card_name} ${laptop.gcm_id === -1 ? "" : `(${laptop.graphic_card_memory} MB)`}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Storage:</strong>{" "}
              {`${laptop.storage_id === -1 ? "" : laptop.storage + "  GB (HDD)"}  
              ${laptop.storage_ssd_id === -1 || laptop.storage_id === -1 ? "" : " & "}
               ${laptop.storage_ssd_id === -1 ? "" : laptop.storage_ssd + "  GB (SSD)"}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>RAM:</strong> {`${laptop.ram} GB (${laptop.ram_type})`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Resolution:</strong> {laptop.resolution}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Refresh Rate:</strong> {`${laptop.refresh_rate} Hz`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Size:</strong> {`${laptop.screen_size} inches`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Type:</strong> {`${laptop.screen_type}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Camera:</strong> {`${laptop.camera_specs}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Speaker:</strong> {`${laptop.speaker}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Keyboard:</strong> {`${laptop.keyboard}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Battery:</strong> {`${laptop.battery}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Backlit:</strong> {`${laptop.backlit === 1 ? "Yes" : "No"}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Touch Screen:</strong> {`${laptop.touch_screen === 1 ? "Yes" : "No"}`}
            </Col>
            {laptop.fp_id === -1 ? (
              ""
            ) : (
              <Col md={12} lg={6} className="px-5 pb-2">
                <strong>Finger Print:</strong> {`${laptop.finger_print}`}
              </Col>
            )}
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Ports:</strong>{" "}
              {laptop.ports.split(",").map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color}
                </span>
              ))}
            </Col>
            <Col md={12} lg={12} className="px-5 pb-2">
              <strong>Description:</strong>
              <div className="border rounded p-3" dangerouslySetInnerHTML={{ __html: laptop.description }} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ViewLaptop;
