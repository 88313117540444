import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";

const ViewLED = ({ id = -1 }) => {
  const navigate = useNavigate();

  const [led, setLed] = useState(null);

  useEffect(() => {
    async function getAccessory(id) {
      const dataRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/leds/" + id);
      setLed(dataRes.data.success.data);
    }
    if (id !== -1) {
      getAccessory(id);
    }
  }, [id]);

  return (
    <>
      {!led ? (
        <Loading />
      ) : (
        <div>
          <div style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
            <h3 className="px-5">
              {led.model_name}
              <hr className="" />
            </h3>
          </div>
          <Row>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Brand:</strong> {led.brand_name}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Title:</strong> {led.title}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Display Type:</strong> {led.display_type}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Colors:</strong>{" "}
              {led.colors_available.map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color.label}
                </span>
              ))}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Resolution:</strong> {led.resolution}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Refresh Rate:</strong> {`${led.refresh_rate} Hz`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Size:</strong> {`${led.screen_size} inches`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Type:</strong> {`${led.screen_type}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Speaker:</strong> {`${led.speaker}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Webcam:</strong> {`${led.webcam === 1 ? "Yes" : "No"}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Smart TV:</strong> {`${led.smart_tv === 1 ? "Yes" : "No"}`}
            </Col>
            {led.smart_tv === 1 ? (
              <>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>Operating System:</strong> {`${led.os}`}
                </Col>
                <Col md={12} lg={6} className="px-5 pb-2">
                  <strong>TV Certification:</strong> {`${led.tv_certification === 1 ? "Yes" : "No"}`}
                </Col>
              </>
            ) : (
              ""
            )}
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Ports:</strong>{" "}
              {led.ports.split(",").map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color}
                </span>
              ))}
            </Col>

            <Col md={12} lg={12} className="px-5 pb-2">
              <strong>Description:</strong>
              <div className="border rounded p-3" dangerouslySetInnerHTML={{ __html: led.description }} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ViewLED;
