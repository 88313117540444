import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import useApi from "../Hooks/useApi";
import * as api from "../api/getapi";
import UploadImageByColor from "../Components/UploadImageByColor";
function Images() {
  const navigate = useNavigate();

  const { id } = useParams();
  const getColors = useApi(api.Colors);

  const [colors, setColors] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const colorRes = await getColors.request(id);
        if (colorRes?.data?.code === 200) {
          setError(false);
          setColors(colorRes.data.success.data);
        } else {
          setError(true);
          setErrorMessage(colorRes.data.error.message);
        }
      } catch (error) {}
    };
    fetchColors();
  }, [id]);

  return (
    <div className="container">
      <button className="backFormButton" onClick={() => navigate(-1)}>
        <span>Back</span>
      </button>
      {error ? (
        <div className="d-flex justify-content-center vh-100 align-items-center">
          <h2 className="d-inline">{errorMessage}</h2>
        </div>
      ) : (
        <>
          <div className="d-flex w-100 justify-content-center m-4">
            <h3>{colors?.title}</h3>
          </div>
          <Accordion defaultActiveKey="0">
            {colors?.colors_available?.map((data, index) => (
              <UploadImageByColor
                data={data}
                key={index}
                index={index}
                id={id}
              />
            ))}
          </Accordion>
        </>
      )}
    </div>
  );
}

export default Images;
