import React from "react";
import { Form } from "react-bootstrap";

function InputControl({ label, type, important, placeholder, value, onChange, disabled, required, feedback, ...props }) {
  return (
    <Form.Group {...(props.as ? { as: props.as } : {})} {...(props.lg ? { lg: props.lg } : {})} {...(props.md ? { md: props.md } : {})} {...(props.sm ? { sm: props.sm } : {})} className={`mb-3 ${props.className}`} controlId={label}>
      <Form.Label className={`text-capitalize ${important ? "asterik-label" : ""}`}>{label}:</Form.Label>
      <Form.Control type={type} placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} {...(required ? { required: true } : {})} />
      {feedback ? <Form.Control.Feedback type={feedback.type}>{feedback.message}</Form.Control.Feedback> : <></>}
    </Form.Group>
  );
}

export default InputControl;
