import React, { useState } from "react";
import { Button, Dropdown, Modal, Table, Row, Col } from "react-bootstrap";

import LodingGif from "../../assets/images/RollingLoader.gif";

import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";

import sortAsc from "../../assets/svg/icon.svg";
import sortDesc from "../../assets/svg/Up.svg";

import removeIcon from "../../assets/svg/remove.svg";

import editpencil from "../../assets/svg/editpencil.svg";
import styles from "../../styles/CustomizeTableView.css";
import ellipsis from "../../assets/svg/Ellipsis.svg";
import printIcon from "../../assets/svg/print.svg";
import eyeIcon from "../../assets/svg/union.svg";

import archiveIcon from "../../assets/svg/archive.svg";
import unarchiveIcon from "../../assets/svg/Unarchive.svg";
import duplicateIcon from "../../assets/svg/save.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faClose, faFileExcel, faRectangleList } from "@fortawesome/free-solid-svg-icons";

const transform = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    if (txt.toLowerCase() === "no") {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1) + ".";
    } else if (txt.toLowerCase() === "incl") {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1) + ".";
    } else {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
    }
  });
  return ret;
};

const TableviewWithCheckbox = ({
  data,
  dummy,
  cancel,
  done,
  edit = null,
  remove = null,
  duplicate = null,
  archive = null,
  unarchive = null,
  columnNotShow = [],
  sortColumn = [],
  orderBy = null,
  setOrderBy = null,
  status = null,
  className,
  style,
  print = null,
  view = null,
  actionButton = null,
  actionButton1 = null,
  actions = true,
  isToggled,
  borderRounded = true,
  checkbox,
  link,
  fixedColumnTable = false,
  firstColumnWidth,
}) => {
  const [id, setId] = useState(-1);
  const [modelDeleteShow, setModalDeleteShow] = useState(false);
  const [modelArchiveShow, setModalArchiveShow] = useState(false);
  const [modelUnArchiveShow, setModalUnArchiveShow] = useState(false);

  const deleteConfirm = (id) => {
    remove(id);
    setModalDeleteShow(false);
  };
  const cancelConfirm = () => {
    setModalDeleteShow(false);
    setModalArchiveShow(false);
    setModalUnArchiveShow(false);
  };
  const archiveConfirm = (id) => {
    archive.action(id);
    setModalArchiveShow(false);
  };
  const unarchiveConfirm = (id) => {
    unarchive.action(id);
    setModalUnArchiveShow(false);
  };

  const handleSort = (column) => {
    if (orderBy) {
      const temp = new Object();
      if (Object.keys(orderBy)[0] === column) {
        if (orderBy[column] === "ASC") {
          temp[column] = "DESC";
        } else {
          temp[column] = "ASC";
        }
      } else {
        temp[column] = "ASC";
      }
      setOrderBy(temp);
    }
  };

  const handleCheckbox = (e, row) => {
    if (e.target.checked) {
      checkbox.setter((prev) => [...prev, row]);
    } else {
      checkbox.setter((prev) => prev.filter((item) => item[Object.keys(item)[0]] !== row[Object.keys(row)[0]]));
    }
  };

  const checkAllHandle = (e) => {
    if (e.target.checked) {
      checkbox.setter((prev) => [...prev, ...data.filter((item) => !checkbox.data.some((subItem) => subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]]))]);
    } else {
      checkbox.setter((prev) => prev.filter((item) => !data.some((subItem) => subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]])));
    }
  };

  const tdNotShow = [...columnNotShow];
  const thNotShow = [...columnNotShow];

  var statusVar = Array.isArray(status?.column) ? {} : undefined;
  var actionButtonVar = Array.isArray(actionButton?.column) ? {} : undefined;
  var actionButton1Var = Array.isArray(actionButton1?.column) ? {} : undefined;

  const dataFormatForTd = (key, value) => {
    if (!tdNotShow?.includes(key)) {
      if (Array.isArray(status?.column)) {
        if (status?.column.some((col) => key === col)) {
          statusVar[key] = value;
        }
      } else {
        if (status?.column === key) {
          statusVar = value;
        }
      }
      if (Array.isArray(actionButton?.column)) {
        if (actionButton?.column.some((col) => key === col)) {
          actionButtonVar[key] = value;
        }
      } else {
        if (actionButton?.column === key) {
          actionButtonVar = value;
        }
      }
      if (Array.isArray(actionButton1?.column)) {
        if (actionButton1?.column.some((col) => key === col)) {
          actionButton1Var[key] = value;
        }
      } else {
        if (actionButton1?.column === key) {
          actionButton1Var = value;
        }
      }

      if (value === null || value === "") {
        return "--";
      } else {
        return value.toString();
      }
    } else {
      if (Array.isArray(status?.column)) {
        if (status?.column.some((col) => key === col)) {
          statusVar[key] = value;
        }
      } else {
        if (status?.column === key) {
          statusVar = value;
        }
      }
      if (Array.isArray(actionButton?.column)) {
        if (actionButton?.column.some((col) => key === col)) {
          actionButtonVar[key] = value;
        }
      } else {
        if (actionButton?.column === key) {
          actionButtonVar = value;
        }
      }
      if (Array.isArray(actionButton1?.column)) {
        if (actionButton1?.column.some((col) => key === col)) {
          actionButton1Var[key] = value;
        }
      } else {
        if (actionButton1?.column === key) {
          actionButton1Var = value;
        }
      }
    }
  };

  return (
    <>
      {data?.length > 0 ? (
        <div className={`table ${fixedColumnTable ? styles.fixedColumnTable : ""}`}>
          <Table responsive striped className={`${borderRounded ? "rounded-3" : ""} bg-white border-1 border-secondary overflow-auto m-0  text-nowrap ${styles.table_main}   ${className}`}>
            <thead
              className={`${isToggled === false ? `d-none` : ""} text-secondary`}
              style={{
                fontSize: "13px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
            >
              <tr>
                {Object.keys(data[0]).map((s, index) => {
                  if (!thNotShow?.includes(s)) {
                    return (
                      <th
                        className={` py-3 ${index === 1 ? styles.fix_col : ""} ${index === 1 ? styles.shadow : ""} `}
                        key={index}
                        style={{
                          ...(orderBy && Object.keys(orderBy)[0] === s ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                          ...(sortColumn?.includes(s) ? { cursor: "pointer" } : {}),
                        }}
                        onClick={() => {
                          if (sortColumn) {
                            if (sortColumn?.includes(s)) {
                              handleSort(s);
                            }
                          }
                        }}
                      >
                        <span>
                          {checkbox && Object.keys(data[0]).filter((item) => !thNotShow?.includes(item))[0] === s ? (
                            <input type="checkbox" onClick={checkAllHandle} style={{ zIndex: "100" }} className={`mx-2 `} checked={data.every((item) => checkbox?.data?.some((subItem) => subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]]))} />
                          ) : (
                            <></>
                          )}

                          {transform(s)}
                          {sortColumn && sortColumn?.includes(s) && (
                            <img
                              style={{
                                pointerEvents: "none",
                              }}
                              alt="sort"
                              className={"ms-1"}
                              src={orderBy[s] === "ASC" ? sortDesc : sortAsc}
                            />
                          )}
                        </span>
                      </th>
                    );
                  }
                })}

                {status && <th className="py-3">Status</th>}
                {actions && (
                  <th className="border-start text-center py-3" style={{ fontWeight: "400" }}>
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <>
                  <tr className={`${isToggled === false ? `d-none` : ""} border-bottom ${firstColumnWidth === true ? styles.first_column_width : ""}`} key={index}>
                    {Object.entries(row).map(([k, v], i) => {
                      if (link && link?.column === k) {
                        return (
                          <td>
                            {v ? (
                              <a href={v} target="_blank">
                                {link?.label}
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                        );
                      } else {
                        const value = dataFormatForTd(k, v);
                        return value?.length > 0 ? (
                          <td key={i}>
                            <div className="d-flex align-items-center">
                              {checkbox && Object.keys(row).filter((item) => !thNotShow?.includes(item))[0] === k ? (
                                <input
                                  type="checkbox"
                                  onClick={(e) => handleCheckbox(e, row)}
                                  style={{ zIndex: "1000", position: "relative" }}
                                  checked={checkbox?.data?.filter((item) => item[Object.keys(item)[0]] === row[Object.keys(row)[0]]).length > 0 ? true : false}
                                  className={`${checkbox && Object.keys(row).filter((item) => !thNotShow?.includes(item))[0] === k ? ` mx-2 ` : ""}`}
                                />
                              ) : (
                                <></>
                              )}

                              <span>{value}</span>
                            </div>
                          </td>
                        ) : (
                          <></>
                        );
                      }
                    })}

                    {status && <td className={` ${status.style ? status.style(statusVar) : ""}`}>{status.format(statusVar)}</td>}

                    {actions && (
                      <td
                        className={` border-start  `}
                        // style={{ maxWidth: "15%", width: "100%" }}
                      >
                        <div className="d-lg-flex align-items-center justify-content-end gap-3 px-2 d-none ">
                          {actionButton1 && actionButton1?.isShow(actionButton1Var) && (
                            <div>
                              <Button
                                className={actionButton1.className}
                                style={actionButton1.style}
                                variant={typeof actionButton1.variant === "function" ? actionButton1.variant(row["id"], row) : actionButton1.variant}
                                onClick={() => {
                                  actionButton1.onClick(row["id"], row);
                                }}
                              >
                                {typeof actionButton1.label === "function" ? actionButton1.label(row) : actionButton1.label}
                              </Button>
                            </div>
                          )}
                          {actionButton && actionButton?.isShow(actionButtonVar) && (
                            <div>
                              <Button
                                className={actionButton.className}
                                style={actionButton.style}
                                variant={typeof actionButton.variant === "function" ? actionButton.variant(row["id"], row) : actionButton.variant}
                                onClick={() => {
                                  actionButton.onClick(row["id"], row);
                                }}
                              >
                                {typeof actionButton.label === "function" ? actionButton.label(row) : actionButton.label}
                              </Button>
                            </div>
                          )}

                          {duplicate && (
                            <div style={{ cursor: "pointer" }} onClick={() => duplicate(row["id"], row["is_miscellaneous"])} className="tooltipp">
                              <img
                                src={duplicateIcon}
                                alt="save"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">Duplicate</span>
                            </div>
                          )}

                          {(typeof edit === "object" ? edit?.isShow(row) : edit) && (
                            <div style={{ cursor: "pointer" }} onClick={() => (typeof edit === "object" ? edit?.action(row["id"], row) : edit(row["id"], row))} className="tooltipp">
                              <img
                                src={editpencil}
                                alt="edit"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">Edit</span>
                            </div>
                          )}
                          {archive && archive.isShow(row) && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModalArchiveShow(true);
                                setId(row["id"]);
                              }}
                              className="tooltipp"
                            >
                              <img
                                src={archiveIcon}
                                alt="archive"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">Archive</span>
                            </div>
                          )}
                          {unarchive && unarchive.isShow(row) && (
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setModalUnArchiveShow(true);
                                setId(row["id"]);
                              }}
                              className="tooltipp"
                            >
                              <img
                                src={unarchiveIcon}
                                alt="unarchive"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">Unarchive</span>
                            </div>
                          )}
                          {remove && (
                            <div
                              onClick={(e) => {
                                setModalDeleteShow(true);
                                setId(row["id"]);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                              className="tooltipp"
                            >
                              <img
                                src={removeIcon}
                                alt="removeIcon"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">Delete</span>
                            </div>
                          )}

                          {(typeof print === "object" ? print?.isShow(row) : print) && (
                            <div style={{ cursor: "pointer" }} onClick={() => (typeof print === "object" ? print?.action(row["id"]) : print(row["id"]))} className="tooltipp">
                              <img
                                src={printIcon}
                                alt="removeIcon"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">Print</span>
                            </div>
                          )}
                          {(typeof view === "object" ? view?.isShow(row) : view) && (
                            <div style={{ cursor: "pointer" }} onClick={() => (typeof view === "object" ? view?.action(row["id"]) : view(row["id"]))} className="tooltipp">
                              <img
                                src={eyeIcon}
                                alt="removeIcon"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="tooltiptext">View</span>
                            </div>
                          )}

                          {dummy && (
                            <div style={{ cursor: "pointer" }} onClick={() => dummy(row)} className="tooltipp">
                              <FontAwesomeIcon icon={faFileExcel} />
                              <span className="tooltiptext">Dummy</span>
                            </div>
                          )}
                          {done && row.status === "Pending" && (
                            <div style={{ cursor: "pointer" }} onClick={() => done(row.id)} className="tooltipp">
                              <FontAwesomeIcon icon={faCheckSquare} />
                              <span className="tooltiptext">Done</span>
                            </div>
                          )}
                          {cancel && row.status === "Pending" && (
                            <div style={{ cursor: "pointer" }} onClick={() => cancel(row.id)} className="tooltipp">
                              <FontAwesomeIcon icon={faClose} />
                              <span className="tooltiptext">Cancel</span>
                            </div>
                          )}
                        </div>

                        <Dropdown className="d-flex justify-content-center d-lg-none">
                          <Dropdown.Toggle variant="light" className={` border-0 p-0 toggle`} id="dropdown-basic" style={{ background: "transparent" }}>
                            <img src={ellipsis} alt="ellipsis" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className=" position-fixed rounded-0 border-0 shadow">
                            {actionButton1 && actionButton1?.isShow(actionButton1Var) && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  actionButton1.onClick(row["id"], row);
                                }}
                              >
                                {typeof actionButton1.label === "function" ? actionButton1.label(row) : actionButton1.label}
                              </Dropdown.Item>
                            )}
                            {actionButton && actionButton?.isShow(actionButtonVar) && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  actionButton.onClick(row["id"], row);
                                }}
                              >
                                {typeof actionButton.label === "function" ? actionButton.label(row) : actionButton.label}
                              </Dropdown.Item>
                            )}

                            {duplicate && (
                              <Dropdown.Item className="purple" onClick={() => duplicate(row["id"], row["is_miscellaneous"])}>
                                Duplicate
                              </Dropdown.Item>
                            )}
                            {dummy && (
                              <Dropdown.Item className="purple" onClick={() => dummy(row)}>
                                Dummy
                              </Dropdown.Item>
                            )}
                            {(typeof edit === "object" ? edit?.isShow(row) : edit) && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  typeof edit === "object" ? edit?.action(row["id"]) : edit(row["id"], row);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                            )}

                            {unarchive && unarchive.isShow(row) && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  setModalUnArchiveShow(true);
                                  setId(row["id"]);
                                }}
                              >
                                Unarchive
                              </Dropdown.Item>
                            )}
                            {archive && archive.isShow(row) && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  setModalArchiveShow(true);
                                  setId(row["id"]);
                                }}
                              >
                                Archive
                              </Dropdown.Item>
                            )}
                            {remove && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  setModalDeleteShow(true);
                                  setId(row["id"]);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                            {(typeof print === "object" ? print?.isShow(row) : print) && (
                              <Dropdown.Item className="purple" onClick={() => (typeof print === "object" ? print?.action(row["id"]) : print(row["id"]))}>
                                Print
                              </Dropdown.Item>
                            )}
                            {(typeof view === "object" ? view?.isShow(row) : view) && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  typeof view === "object" ? view?.action(row["id"]) : view(row["id"]);
                                }}
                              >
                                View
                              </Dropdown.Item>
                            )}
                            {done && row.status === "Pending" && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  done(row.id);
                                }}
                              >
                                Done
                              </Dropdown.Item>
                            )}
                            {cancel && row.status === "Pending" && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  cancel(row.id);
                                }}
                              >
                                Cancel
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
          {/* </div> */}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "600px" }}>
          No Result Found
        </div>
      )}

      <Modal show={modelDeleteShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to delete?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100" onClick={() => deleteConfirm(id)}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Archive Model */}

      <Modal show={modelArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Archive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            {/* <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button> */}
            <Button className="w-100 rounded-3" onClick={() => archiveConfirm(id)}>
              Archive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* UnArchive Model */}

      <Modal show={modelUnArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Unarchive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Unarchive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            {/* <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button> */}
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100 rounded-3" onClick={() => unarchiveConfirm(id)}>
              Unarchive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableviewWithCheckbox;
