import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";

const ViewMobile = ({ id = -1 }) => {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(null);

  useEffect(() => {
    async function getAccessory(id) {
      const dataRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/mobiles/" + id);
      setMobile(dataRes.data.success.data);
    }
    if (id !== -1) {
      getAccessory(id);
    }
  }, [id]);

  return (
    <>
      {!mobile ? (
        <Loading />
      ) : (
        <div>
          <div style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
            <h3 className="px-5">
              {mobile.model_name}
              <hr className="" />
            </h3>
          </div>
          <Row>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Brand:</strong> {mobile.brand_name}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Title:</strong> {mobile.title}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Processor:</strong> {mobile.processor}
            </Col>
            <Col
              md={12}
              lg={6}
              className="px-5 pb-2"
              style={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              <strong>Colors:</strong>{" "}
              {mobile.colors_available.map((color, index) => (
                <span key={index} className="bg-light text-dark mx-2 shadow-sm">
                  {color.label}
                </span>
              ))}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>RAM:</strong> {mobile.ram + " GB"}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Storage:</strong> {mobile.storage + " GB"}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Camera:</strong> {mobile.camera_specs + " MP"}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Resolution:</strong> {mobile.resolution}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Refresh Rate:</strong> {`${mobile.refresh_rate} Hz`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Size:</strong> {`${mobile.screen_size} inches`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Type:</strong> {`${mobile.screen_type}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Screen Protection:</strong> {`${mobile.screen_protection}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>SIM type:</strong> {`${mobile.sim_type}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Speaker:</strong> {`${mobile.speaker}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Body Type:</strong> {`${mobile.body_type}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Network Bands:</strong> {`${mobile.network_bands}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Finger Print:</strong> {`${mobile.finger_print}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Battery Capacity:</strong> {`${mobile.battery_capacity} mAh`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>E-Sim:</strong> {`${mobile.e_sim === 1 ? "Yes" : "No"}`}
            </Col>
            <Col md={12} lg={6} className="px-5 pb-2">
              <strong>Port:</strong> <span className="bg-light text-dark mx-2 shadow-sm">{mobile.ports}</span>
            </Col>
            <Col md={12} lg={12} className="px-5 pb-2">
              <strong>Description:</strong>
              <div className="border rounded p-3" dangerouslySetInnerHTML={{ __html: mobile.description }} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ViewMobile;
