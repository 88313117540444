import React, { useState } from "react";
import styles from "./ImportExcelModalReleaseDateAndLaunchPrice.module.css";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

export default function ImportExcelModalReleaseDateAndLaunchPrice({ show, close }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const onModalHideHandle = () => {
    if (!isUploading) {
      setSelectedFile(null);
      close();
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      if (event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setError("");
        setSelectedFile(event.target.files[0]);
      } else {
        setError("You are trying to upload a file that is not a Excel file.");
      }
    } else {
      setError("Please select a Excel file.");
    }
  };

  const onSubmit = async () => {
    try {
      if (error?.length === 0) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        setIsUploading(true);
        const response = await axios.patch(process.env.REACT_APP_URL_API_DATA + "/model/bulk-update", formData, { withCredentials: true, headers: { "Content-Type": "multipart/form-data" } });
        if (response) {
          setIsUploading(false);
          onModalHideHandle();
          const responseData = response?.data?.success?.data;
          if (responseData) {
            if (responseData?.updated === 0) {
              if (responseData?.unchanged === 0) {
                toast.success("There is nothing to change!");
              } else {
                toast.error(`Error to update ${responseData?.unchanged} variants!`);
              }
            } else {
              toast.success(`${responseData?.updated} variants updated successfully!`, {
                // position: "bottom-right",
                // autoClose: 5000,
                // hideProgressBar: true,
                // closeOnClick: true,
                // pauseOnHover: true,
                // draggable: false,
                // progress: undefined,
                // theme: "light",
              });
            }
          } else {
            toast.error("Issue with backend response!");
          }
        }
      }
    } catch (err) {
      setIsUploading(false);
      toast.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <>
      <Modal show={show} onHide={onModalHideHandle} className={`${styles.modal}`} centered size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static">
        <Modal.Header closeButton>Import Excel for Model</Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-3">
            <div>
              <label></label>
              <input type="file" multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange} disabled={isUploading} />
            </div>
            {error?.length > 0 ? <div className="text-danger pt-2">{error}</div> : <></>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onModalHideHandle} disabled={isUploading}>
            Close
          </Button>
          <Button onClick={onSubmit} disabled={isUploading}>
            {isUploading ? "Uploading..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
