import { Link, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import RanIntoProblem from "../../Components/RanIntoProblem";

const ComponentsList = ({ components }) => {
  const navigate = useNavigate();
  const handleRoute = (table) => {
    navigate(`/components/${table}`);
  };
  return (
    <>
      <div>
        <div className="row justify-content-start w-100">
          {components ? (
            components?.map((data) => (
              <>
                <div
                  onClick={() => handleRoute(data.associated_table)}
                  className="col-md-2 mt-3"
                  role="button"
                >
                  <div className="card d-flex text-center h-100">
                    <div className="card-header">{data.name}</div>
                  </div>
                </div>
              </>
            ))
          ) : components?.length === 0 ? (
            <RanIntoProblem />
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
};

export default ComponentsList;
