import { useSelector, useDispatch } from "react-redux";
import Cookies from "./Features/Cookies";
import { currentUser, authActions } from "./Features/authSlice";
import { Routes, useLocation, Navigate, Outlet, Route } from "react-router-dom";
import { useLayoutEffect } from "react";

import DashboardLayout from "./Layout/DashboardLayout";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ComponentPage from "./Pages/Component/ComponentPage";
import ComponentHomePage from "./Pages/Component/ComponentHomePage";
import Requests from "./Pages/Requests";

import Images from "./Pages/Images";

import Accessories from "./Pages/Accessories/Accessories";
import AccessoriesFormPage from "./Pages/Accessories/AccessoriesFormPage";
import TvMonitors from "./Pages/TvMonitors/TvMonitors";
import TvMonitorsFormPage from "./Pages/TvMonitors/TvMonitorsFormPage";
import Laptops from "./Pages/Laptops/Laptops";
import LaptopsFormPage from "./Pages/Laptops/LaptopsFormPage";
import DesktopComputers from "./Pages/DesktopComputers/DesktopComputers";
import DesktopComputersFormPage from "./Pages/DesktopComputers/DesktopComputersFormPage";
import Mobiles from "./Pages/Mobiles/Mobiles";
import MobilesFormPage from "./Pages/Mobiles/MobilesFormPage";
import Tablets from "./Pages/Tablets/Tablets";
import TabletsFormPage from "./Pages/Tablets/TabletsFormPage";

const RedirectComponent = () => {
  const location = useLocation();

  const token = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN);
  const dispatch = useDispatch();
  const refreshToken = localStorage.getItem("refreshToken");
  useLayoutEffect(() => {
    if (token) {
      dispatch(currentUser(refreshToken));
    } else {
      dispatch(authActions.logout());
    }
  }, [dispatch, refreshToken, token, location]);

  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        {user && token ? (
          <Route
            path="*"
            element={
              <Routes>
                <Route path="/" element={<DashboardLayout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="requests" element={<Requests />} />
                  <Route path="laptops" element={<Outlet />}>
                    <Route exact index element={<Laptops />} />
                    <Route path=":mode" element={<LaptopsFormPage />} />
                    <Route path=":mode/:id" element={<LaptopsFormPage />} />
                  </Route>
                  <Route path="mobiles" element={<Outlet />}>
                    <Route exact index element={<Mobiles />} />
                    <Route path=":mode" element={<MobilesFormPage />} />
                    <Route path=":mode/:id" element={<MobilesFormPage />} />
                  </Route>
                  <Route path="tablets" element={<Outlet />}>
                    <Route exact index element={<Tablets />} />
                    <Route path=":mode" element={<TabletsFormPage />} />
                    <Route path=":mode/:id" element={<TabletsFormPage />} />
                  </Route>
                  <Route path="tv-monitors" element={<Outlet />}>
                    <Route exact index element={<TvMonitors />} />
                    <Route path=":mode" element={<TvMonitorsFormPage />} />
                    <Route path=":mode/:id" element={<TvMonitorsFormPage />} />
                  </Route>
                  <Route path="desktop-computers" element={<Outlet />}>
                    <Route exact index element={<DesktopComputers />} />
                    <Route path=":mode" element={<DesktopComputersFormPage />} />
                    <Route path=":mode/:id" element={<DesktopComputersFormPage />} />
                  </Route>
                  <Route path="images/:id" element={<Images />} />
                  <Route path="accessories" element={<Outlet />}>
                    <Route exact index element={<Accessories />} />
                    <Route path=":mode" element={<AccessoriesFormPage />} />
                    <Route path=":mode/:id" element={<AccessoriesFormPage />} />
                  </Route>
                  <Route path="components" element={<ComponentHomePage />}>
                    <Route path=":component" element={<ComponentPage />} />
                  </Route>
                  <Route exact path="/login" element={<Navigate to="/" />} />
                  <Route exact path="*" element={<Navigate to="/" />} />
                </Route>
              </Routes>
            }
          />
        ) : (
          <Route
            path="*"
            element={
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="*" element={token ? <Outlet /> : refreshToken ? <Navigate to="/login" /> : <Navigate to="/login" />} />
              </Routes>
            }
          />
        )}
      </Routes>
    </>
  );
};

export default RedirectComponent;
