import { useState, useRef } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import ImageUploader from "../ImageUploader/ImageUploader";

const AddComponent = ({ fieldData, toggle, close, data = null }) => {
  //form validation
  const [validated, setValidated] = useState(false);
  //vendors
  const [inputData, setInputData] = useState({
    label: null,
    ...Object.keys(fieldData.is_filters === 1 ? fieldData.filters : {}).reduce(
      (obj, key) => ({
        ...obj,
        [key]: false,
      }),
      {}
    ),
  });
  const [isFilterSelect, setIsFilterSelect] = useState(false);

  const addComponentApi = useApi(postapi.addComponent);
  const editComponentApi = useApi(postapi.updateComponent);
  const uploadBrandLogoApi = useApi(postapi.uploadBrandLogo);
  const [error, setError] = useState("");
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [lengthExceedError, setLengthExceedError] = useState("");

  // Related to Image Uploader
  const imageComponent = useRef();
  const [isWorking, setIsWorking] = useState(true);
  const [refill, setRefill] = useState(true);
  const [images, setImages] = useState({ old: [], current: [] });
  const [deletedImages, setDeletedImages] = useState([]);

  const selectImageHandle = (callback) => {
    // We shall allow only one image to be uploaded
    if (typeof callback === "function") {
      setImages((prev) => ({ ...prev, current: callback(prev?.current) }));
    } else {
      setImages((prev) => ({ ...prev, current: callback }));
    }
  };

  const inputOnHandle = (e) => {
    const setState = (name, value) => {
      setInputData((prev) => {
        return { ...prev, [name]: value };
      });
    };
    const format = /^[0-9]*$/;
    if (fieldData.modal_input_type === "number") {
      if (e.target.value.length < 11) {
        setLengthExceedError("");
        if (format.test(e.target.value)) {
          setState(e.target.name, e.target.value);
        } else {
          setState(e.target.name, "");
        }
      } else {
        setLengthExceedError("Maximum Digit you Entered is 10");
      }
    } else if (fieldData.modal_input_type === "text") {
      if (e.target.value.length < 250) {
        setLengthExceedError("");
        setState(e.target.name, e.target.value);
      } else {
        setLengthExceedError("Maximum Characters you Entered is 250");
      }
    } else if (fieldData.modal_input_type === "decimal") {
      const [first, second] = e.target.value.split(".");
      let endResult;
      let firstPart;
      if (format.test(first)) {
        if (first?.length < 6) {
          setLengthExceedError("");
          firstPart = first;
        } else {
          setLengthExceedError("Maximum Digit before Decimal is 5");
          firstPart = first.slice(0, 5);
        }
      }
      if (e.target.value.includes(".")) {
        if (second?.length > 0) {
          if (format.test(second)) {
            if (second?.length < 3) {
              setLengthExceedError("");
              endResult = [firstPart, second].join(".");
            } else {
              setLengthExceedError("Maximum Digit after Decimal is 2");
              endResult = [firstPart, second.slice(0, 2)].join(".");
            }
          }
        } else {
          endResult = firstPart + ".";
        }
      } else {
        if (firstPart && format.test(firstPart)) {
          endResult = firstPart;
        } else {
          endResult = "";
        }
      }
      setState(e.target.name, endResult);
    }
  };
  const filterOnHandle = (e) => {
    setInputData((prev) => {
      return { ...prev, [e.target.name]: e.target.checked };
    });
  };
  const submitHandle = async (e) => {
    setIsSubmitButtonClicked(true);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    if (fieldData.modal_input_type === "decimal") {
      if (inputData["label"]?.substr(-1) === ".") {
        setLengthExceedError("There must be a digit after decimal");
      } else {
        setLengthExceedError("");
      }
    }
    if (
      inputData["label"]?.length > 0 &&
      (fieldData.is_filters === 0 ? true : isFilterSelect) &&
      inputData["label"]?.substr(-1) !== "."
    ) {
      try {
        if (fieldData.modal_input_type === "decimal") {
          inputData["label"] = parseFloat(inputData["label"]).toFixed(2);
        }

        // In case of brand, we need to send the images as well
        if (fieldData.associated_table === "brand") {
          const formData = new FormData();
          let requestObject = {};
          const image = images?.current[0];
          if (!image) {
            setIsSubmitButtonClicked(false);
            setError("Please upload an image");
            return;
          }
          if (image && image.type === "file") {
            formData.append("files", image.data, image.data.name);
          } else {
            requestObject["url"] = image.data;
          }

          formData.append("deleted", JSON.stringify(deletedImages));
          const cdnResponse = await uploadBrandLogoApi.request(formData);
          if (cdnResponse.status !== 200) {
            setError("Error in uploading image");
            return;
          }
          const { key } = cdnResponse.data;
          inputData["logo"] = key;
        }

        let res;
        if (data) {
          const { created_at, ...dataObj } = inputData;

          res = await editComponentApi.request(
            fieldData.associated_table,
            data.id,
            dataObj
          );
        } else {
          res = await addComponentApi.request(
            fieldData.associated_table,
            inputData
          );
        }
        if (res.data.code === 500) {
          setError(res.data.error.message);
        } else {
          toggle((prev) => !prev);
          close();
        }
      } catch (error) {
        setError(error?.response?.data?.error?.message);
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    if (data) {
      const { id, is_deleted, ...tempInput } = data;
      tempInput["label"] = tempInput["label"].toString();
      setInputData(tempInput);
      if (fieldData.associated_table === "brand") {
        if (!tempInput["logo"].includes("broken") && tempInput["logo"] !== "") {
          setImages({
            old: [],
            current: [{ preview: tempInput["logo"], type: "url" }],
          });
        }
      }
    }
  }, [data]);

  useEffect(() => {
    setIsFilterSelect(
      Object.entries(inputData).reduce((output, [key, value]) => {
        if (key !== "label" && key !== "created_at" && key !== "is_deleted") {
          output = output || value;
        }
        return output;
      }, false)
    );
  }, [inputData]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={submitHandle}>
        {error && (
          <span className="text-danger p-2 text-center my-2 rounded-1">
            *{error}
          </span>
        )}

        <Row className="">
          <Col className="py-2">
            <Form.Group className="mb-3" controlId="price">
              <label>
                {fieldData.modal_input_label}{" "}
                <span className="text-danger">*</span>
              </label>
              <Form.Control
                value={inputData["label"]}
                name="label"
                onChange={inputOnHandle}
                // type={fieldData.modal_input_type}
                type={"text"}
                placeholder={fieldData.modal_input_placeholder}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter value.
              </Form.Control.Feedback>
              {lengthExceedError.length > 0 ? (
                <div
                  className="text-center p-2"
                  style={{
                    border: "1px dashed #dedfe4",
                    backgroundColor: "#dedfe47a",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                >
                  {lengthExceedError}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
          {fieldData.is_filters === 1 ? (
            <Row className="py-2 mb-2">
              <Form.Label className="mb-1">
                Devices <span style={{ color: "red" }}>*</span>
              </Form.Label>
              {Object.entries(fieldData.filters).map(([key, value]) => {
                //    return <option value={key} >{value.label}</option>
                return (
                  <Col lg={"6"}>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        label={value.label}
                        checked={!!inputData[key] === true}
                        name={key}
                        onChange={filterOnHandle}
                      />
                    </Form.Group>
                  </Col>
                );
              })}
              <Form.Group>
                <Form.Check
                  className="mb-3 d-flex"
                  checked={isFilterSelect}
                  required
                  hidden
                  feedback="Please checked atleast one device."
                  feedbackType="invalid"
                />
              </Form.Group>
            </Row>
          ) : (
            ""
          )}
        </Row>

        {fieldData.associated_table === "brand" ? (
          <Row className="py-2">
            <Col>
              <ImageUploader
                ref={imageComponent}
                selectedFiles={images?.current}
                setSelectedFiles={selectImageHandle}
                setDeletedImages={setDeletedImages}
                isWorking={isWorking}
                allowedImages={1}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row className="py-3">
          <Col>
            <Button
              className="w-100"
              type="submit"
              disabled={isSubmitButtonClicked}
            >
              {data
                ? fieldData.modal_update_button_label
                : fieldData.modal_add_button_label}
            </Button>
          </Col>
        </Row>
      </Form>
      {/* <ToastContainer /> */}
    </>
  );
};

export default AddComponent;
