import React, { useState } from "react";
import TableviewWithCheckbox from "../TableviewWithCheckbox/TableviewWithCheckbox";
import styles from "./ExportExcelModal.module.css";
import { Button, Modal } from "react-bootstrap";
import PaginationBar from "../PaginationBar/PaginationBar";
import axios from "axios";
import ExcelButton from "../ExcelButton/ExcelButton";

export default function ExportExcelModal({ title = "Metadata", show, close, data, currentPage, changePage, search, setSearch, onlyModal = true, exportFormat, filters }) {
  const [selectedModel, setSelectedModel] = useState([]);
  const [error, setError] = useState("");
  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };
  const onModalHideHandle = () => {
    setSelectedModel([]);
    close();
  };
  const excelAPIcall = () => {
    if (selectedModel.length < 1) {
      setError("Please select at least one model");
    } else {
      setError("");
      let ids;
      if (onlyModal) {
        ids = selectedModel.reduce((acc, item) => [...acc, item.ids.split(",")], []);
      } else {
        ids = selectedModel.map((item) => item.id);
      }
      return axios.post(process.env.REACT_APP_URL_API_DATA + "/model/get-by-ids", { ids: ids, ...(onlyModal ? { onlyModel: true } : { withReleaseDate: true, withLaunchPrice: true }) }, { withCredentials: true });
    }
  };

  return (
    <>
      <Modal show={show} onHide={onModalHideHandle} className={`${styles.modal}`} centered size="lg" aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>Export Excel for {title}</Modal.Header>
        <Modal.Body className="p-0">
          <div className={`border-bottom p-2 d-flex justify-content-between`}>
            <input type="search" placeholder="Search" className="px-3 rounded" value={search} onChange={handleSearchInput} />
            {filters}
            {error && error.length > 0 ? <p className="text-danger">{error}</p> : <></>}
          </div>
          <TableviewWithCheckbox data={data?.items} checkbox={{ data: selectedModel, setter: setSelectedModel }} columnNotShow={["colors_available", "images", "model_name"]} actions={false} />
          {data?.items?.length > 0 && <PaginationBar noOfPages={data?.noOfPages} currentPage={currentPage} changePage={changePage} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onModalHideHandle}>
            Close
          </Button>
          <ExcelButton api={excelAPIcall} filename={"Models Metadata " + new Date().toDateString()} responseObject={(data) => data?.data?.data} format={(data) => (exportFormat ? exportFormat(data) : data)} onComplete={onModalHideHandle} error={setError} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
