import React, { useState } from "react";
import { Alert, Button, Dropdown, Modal, Table } from "react-bootstrap";
import Loader from "../Loader";

import "../../styles/CustomizeTableView.css";

import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";

import sortAsc from "../../assets/svg/icon.svg";
import sortDesc from "../../assets/svg/Up.svg";

import removeIcon from "../../assets/svg/remove.svg";

import editpencil from "../../assets/svg/editpencil.svg";

import ellipsis from "../../assets/svg/Ellipsis.svg";
import printIcon from "../../assets/svg/print.svg";
import emailIcon from "../../assets/svg/email.svg";
import eyeIcon from "../../assets/svg/union.svg";

import notesIcon from "../../assets/svg/purchase_order.svg";
import paymentIcon from "../../assets/svg/paymentIcon.svg";

import archiveIcon from "../../assets/svg/archive.svg";
import unarchiveIcon from "../../assets/svg/Unarchive.svg";
import duplicateIcon from "../../assets/svg/save.svg";
import settingsIcon from "../../assets/svg/settings.svg";

const transform = (text) => {
  if (text) {
    const temp = text.split("_");
    let ret = "";
    temp.forEach((txt) => {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
    });
    return ret;
  }
  return text;
};

const CustomizeTableview = ({
  data,
  reasons,
  settings = null,
  edit = null,
  remove = null,
  duplicate = null,
  archive = null,
  unarchive = null,
  columnNotShow = [],
  dateColumn = [],
  datetimeColumn = [],
  sortColumn = [],
  thumbnail = null,
  orderBy = null,
  setOrderBy = null,
  status = null,
  className,
  style,
  visitButton,
  loginButton = null,
  print = null,
  view = null,
  email = null,
  notes = null,
  payment = null,
  actionButton = null,
  actionButton1 = null,
  toggleActionButton = null,
  ActiveDeactive = null,
  actions = true,
  salesman,
  assign,
  cancelPlan,
  ChangePlan,
  expireButton,
  requestDescription,
  NextPlan,
}) => {
  const [id, setId] = useState(-1);
  const [unarchivePassword, setUnarchivePassword] = useState("");
  const [unarchiveError, setUnarchiveError] = useState("");
  const [archivePassword, setArchivePassword] = useState("");
  const [archiveError, setArchiveError] = useState("");
  const [modelDeleteShow, setModalDeleteShow] = useState(false);
  const [modelArchiveShow, setModalArchiveShow] = useState(false);
  const [modelUnArchiveShow, setModalUnArchiveShow] = useState(false);

  const deleteConfirm = (id) => {
    remove(id);
    setModalDeleteShow(false);
  };

  const cancelConfirm = () => {
    setModalDeleteShow(false);
    setModalArchiveShow(false);
    setModalUnArchiveShow(false);
    setArchivePassword("");
    setArchiveError("");
    setUnarchivePassword("");
    setUnarchiveError("");
  };
  const archiveConfirm = (id, password) => {
    archive(id, password, (error) => {
      if (error) {
        setArchiveError(error);
      } else {
        setModalArchiveShow(false);
        setArchivePassword("");
        setArchiveError("");
      }
    });
  };
  const unarchiveConfirm = (id, password) => {
    unarchive(id, password, (error) => {
      if (error) {
        setUnarchiveError(error);
      } else {
        setModalUnArchiveShow(false);
        setUnarchivePassword("");
        setUnarchiveError("");
      }
    });
  };
  const handleSort = (column) => {
    if (orderBy) {
      const temp = new Object();
      if (Object.keys(orderBy)[0] === column) {
        if (orderBy[column] === "ASC") {
          temp[column] = "DESC";
        } else {
          temp[column] = "ASC";
        }
      } else {
        temp[column] = "ASC";
      }
      setOrderBy(temp);
    }
  };

  const tdNotShow = [...columnNotShow, ...dateColumn, ...datetimeColumn];
  if (thumbnail) {
    tdNotShow.push(thumbnail?.img, thumbnail?.description);
  }

  var statusVar;
  var actionButtonVar;
  const FIELD_LENGTH = 50;

  const TB = ({ data }) => {
    const [full, setFull] = useState(false);
    return (
      <td className={`border-bottom-0  `}>
        {full
          ? `${data}`
          : data.length > FIELD_LENGTH
          ? `${data.substring(0, FIELD_LENGTH)}`
          : `${data}`}
        {full ? (
          data.length > FIELD_LENGTH ? (
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => setFull(false)}
            >
              (less)
            </span>
          ) : (
            ""
          )
        ) : data.length > FIELD_LENGTH ? (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => setFull(true)}
          >
            (...)
          </span>
        ) : (
          ``
        )}
      </td>
    );
  };
  return (
    <>
      {!data[0] ? (
        <div
          style={{ height: "calc(100vh - 400px)" }}
          className="w-100 d-flex justify-content-center align-items-center"
        >
          <Loader />
        </div>
      ) : (
        <Table
          responsive
          striped
          className={`rounded-3 text-center bg-white border-1 border-secondary overflow-hidden m-0 mb-0 text-nowrap ${className}`}
        >
          <thead
            className="text-secondary"
            style={{ fontSize: "13px", fontWeight: "400", lineHeight: "18px" }}
          >
            <tr>
              {thumbnail && (
                <th
                  style={{
                    ...(orderBy &&
                    Object.keys(orderBy)[0] === thumbnail?.description
                      ? { color: "#9537FF" }
                      : {}),
                    ...(sortColumn.includes(thumbnail?.description)
                      ? { cursor: "pointer" }
                      : {}),
                  }}
                  onClick={() => {
                    if (sortColumn) {
                      if (sortColumn.includes(thumbnail?.description)) {
                        handleSort(thumbnail?.description);
                      }
                    }
                  }}
                >
                  {transform(thumbnail?.title)}
                  {sortColumn &&
                    sortColumn.includes(thumbnail?.description) && (
                      <img
                        src={
                          orderBy[thumbnail?.description] === "ASC"
                            ? sortDesc
                            : sortAsc
                        }
                      />
                    )}
                </th>
              )}
              {Object.keys(data[0]).map((s, index) => {
                if (!columnNotShow?.includes(s)) {
                  return (
                    <th
                      key={index}
                      style={{
                        ...(orderBy && Object.keys(orderBy)[0] === s
                          ? { color: "#9537FF" }
                          : {}),
                        ...(sortColumn.includes(s)
                          ? { cursor: "pointer" }
                          : {}),
                      }}
                      onClick={() => {
                        if (sortColumn) {
                          if (sortColumn.includes(s)) {
                            handleSort(s);
                          }
                        }
                      }}
                    >
                      {transform(s)}
                      {sortColumn && sortColumn.includes(s) && (
                        <img
                          className="ms-2"
                          src={orderBy[s] === "ASC" ? sortDesc : sortAsc}
                        />
                      )}
                    </th>
                  );
                }
              })}
              {requestDescription && <th>Request Description</th>}
              {status && <th>Status</th>}
              {reasons && (
                <th className="border-start text-center">Deactivate Reasons</th>
              )}
              {actions && <th className="border-start text-end">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr className="border-bottom" key={index}>
                {thumbnail && (
                  <td
                    className={`border-bottom-0 ${
                      thumbnail?.className ? thumbnail?.className : ""
                    }`}
                  >
                    <img className="object-fit-contain"
                      style={{ borderRadius: "6px" }}
                      width="32px"
                         height="32px"
                      alt="thumbnail"
                      src={row[thumbnail?.img]}
                    />
                    {row[thumbnail?.description]}
                  </td>
                )}
                {Object.entries(row).map(([k, v], i) => {
                  if (!tdNotShow?.includes(k)) {
                    if (status?.column === k) {
                      statusVar = v;
                    } else if (actionButton?.column === k) {
                      actionButtonVar = v;
                    } else {
                      return (
                        <>
                          {row["is_subscription_cancelled"] === 1 ? (
                            <td className="border-bottom-0 overlay" key={i}>
                              {" "}
                              {v}{" "}
                            </td>
                          ) : (
                            <td className="border-bottom-0" key={i}>
                              {" "}
                              {v}{" "}
                            </td>
                          )}
                        </>
                      );
                    }
                  } else if (dateColumn?.includes(k)) {
                    if (v) {
                      const dateFormat = new Date(v);

                      return (
                        <td className="border-bottom-0" key={i}>
                          {dateFormat
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </td>
                      );
                    } else {
                      return <td className="border-bottom-0" key={i}></td>;
                    }
                  } else if (datetimeColumn?.includes(k)) {
                    if (v) {
                      const dateFormat = new Date(v);
                      return (
                        <td className="border-bottom-0" key={i}>
                          {dateFormat
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}{" "}
                          {dateFormat.toLocaleTimeString()}
                        </td>
                      );
                    } else {
                      return <td className="border-bottom-0" key={i}></td>;
                    }
                  }
                  // return (<td className='border-bottom-0' key={i}>{v}</td>)
                })}
                {requestDescription && (
                  <td className="">
                    <TB data={row["request_description"]} />
                  </td>
                )}
                {status && (
                  <td className="bold-2">
                    <span className={`${status.style(row[status?.column])}`}>
                      {status.format(row[status?.column])}
                    </span>
                  </td>
                )}

                {reasons && (
                  <td>
                    <Button
                      className=" outline-primary"
                      variant="outline-primary"
                      onClick={() => {
                        reasons(row);
                      }}
                    >
                      Reasons
                    </Button>
                  </td>
                )}
                {actions && (
                  <td
                    className={` border-start border-bottom-0 ${
                      row["is_deleted"] === 1 ? "overlay" : ""
                    } `}
                    style={{ maxWidth: "15%", width: "100%" }}
                  >
                    <div className="d-lg-flex align-items-center justify-content-end gap-3 px-2 d-none ">
                      {row["is_deleted"] === 1 ? (
                        <>
                          {unarchive && (
                            <div className="tooltipp">
                              <img
                                src={unarchiveIcon}
                                alt="unarchive"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setModalUnArchiveShow(true);
                                  setId(row["id"]);
                                }}
                              />
                              <span className="tooltiptext">Unarchive</span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {edit && (
                            <div className="tooltipp">
                              <img
                                src={editpencil}
                                alt="edit"
                                style={{ cursor: "pointer" }}
                                onClick={() => edit(row)}
                              />
                              <span className="tooltiptext">Edit</span>
                            </div>
                          )}
                          {archive && (
                            <div className="tooltipp">
                              <img
                                src={archiveIcon}
                                alt="archive"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setModalArchiveShow(true);
                                  setId(row["id"]);
                                }}
                              />
                              <span className="tooltiptext">Archive</span>
                            </div>
                          )}
                        </>
                      )}
                      {actionButton &&
                        actionButton?.isShow(row[actionButton?.column]) && (
                          <div>
                            <Button
                              className={actionButton.className}
                              style={actionButton.style}
                              variant={actionButton.variant}
                              onClick={() => {
                                actionButton.onClick(row["id"]);
                              }}
                            >
                              {actionButton.label}
                            </Button>
                          </div>
                        )}
                      {actionButton1 &&
                        actionButton1?.isShow(row[actionButton?.column]) && (
                          <div>
                            <Button
                              className={actionButton1.className}
                              style={actionButton1.style}
                              variant={actionButton1.variant}
                              onClick={() => {
                                actionButton1.onClick(row["id"]);
                              }}
                            >
                              {actionButton1.label}
                            </Button>
                          </div>
                        )}

                      {ActiveDeactive &&
                        row["is_subscription_cancelled"] === 0 && (
                          <div>
                            <Button
                              className={ActiveDeactive.className}
                              style={ActiveDeactive.style}
                              variant={ActiveDeactive.variant(row)}
                              onClick={() => {
                                ActiveDeactive.onClick(row);
                              }}
                            >
                              {ActiveDeactive.label(row)}
                            </Button>
                          </div>
                        )}
                      {visitButton && (
                        <div>
                          <Button
                            className={visitButton.className}
                            style={visitButton.style}
                            variant={visitButton.variant}
                            onClick={() => {
                              visitButton.onClick(row["username"]);
                            }}
                          >
                            {visitButton.label}
                          </Button>
                        </div>
                      )}
                      {salesman && (
                        <div>
                          <Button
                            className=" outline-primary"
                            variant="outline-primary"
                            onClick={() => {
                              salesman(row);
                            }}
                          >
                            Change Salesman
                          </Button>
                        </div>
                      )}

                      {assign && row["request_status"] === "pending" && (
                        <div>
                          <Button
                            className=" outline-primary"
                            variant="outline-primary"
                            onClick={() => {
                              assign(row);
                            }}
                          >
                            Assign Me
                          </Button>
                        </div>
                      )}
                      {loginButton && (
                        <div>
                          <Button
                            className={loginButton.className}
                            style={loginButton.style}
                            variant={loginButton.variant}
                            onClick={() => {
                              loginButton.onClick(row["username"]);
                            }}
                          >
                            {loginButton.label}
                          </Button>
                        </div>
                      )}
                      {expireButton && row["status"] === "on_trial" && (
                        <Button
                          className=" outline-danger "
                          variant="outline-danger"
                          onClick={() => {
                            expireButton.cancelExpired(row);
                          }}
                        >
                          {expireButton.label}
                        </Button>
                      )}

                      {toggleActionButton && (
                        <div>
                          <Button
                            className={toggleActionButton.className}
                            style={toggleActionButton.style}
                            variant={toggleActionButton.variant(row)}
                            onClick={() => {
                              toggleActionButton.onClick(row);
                            }}
                          >
                            {toggleActionButton.label(row)}
                          </Button>
                        </div>
                      )}

                      {duplicate && (
                        <div className="tooltipp">
                          <img
                            src={duplicateIcon}
                            alt="save"
                            style={{ cursor: "pointer" }}
                            onClick={() => duplicate(row.id)}
                          />
                          <span className="tooltiptext">Duplicate</span>
                        </div>
                      )}
                      {ChangePlan && (
                        <Button
                          className=" outline-primay "
                          variant="outline-primary"
                          onClick={() => {
                            ChangePlan.changeplan(row);
                          }}
                        >
                          {ChangePlan.label}
                        </Button>
                      )}
                      {NextPlan && (
                        <Button
                          className=" outline-primay "
                          variant="outline-primary"
                          onClick={() => {
                            NextPlan.nextplan(row);
                          }}
                        >
                          {NextPlan.label}
                        </Button>
                      )}
                      {cancelPlan && (
                        <Button
                          className=" outline-danger "
                          variant="outline-danger"
                          onClick={() => {
                            cancelPlan.cancelExpired(row);
                          }}
                        >
                          {cancelPlan.label}
                        </Button>
                      )}
                      {settings && (
                        <div className="tooltipp">
                          <img
                            src={settingsIcon}
                            alt="edit"
                            style={{ cursor: "pointer" }}
                            onClick={() => settings(row["id"])}
                          />
                          <span className="tooltiptext">Reset Password</span>
                        </div>
                      )}

                      {remove && (
                        <div className="tooltipp">
                          <img
                            src={removeIcon}
                            alt="removeIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModalDeleteShow(true);
                              setId(row);
                            }}
                          />
                          <span className="tooltiptext">Delete</span>
                        </div>
                      )}
                      {print && (
                        <div className="tooltipp">
                          <img
                            src={printIcon}
                            alt="removeIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => print(row["id"])}
                          />
                          <span className="tooltiptext">Print</span>
                        </div>
                      )}
                      {view && (
                        <div className="tooltipp">
                          <img
                            src={eyeIcon}
                            alt="removeIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => view(row["id"])}
                          />
                          <span className="tooltiptext">View</span>
                        </div>
                      )}
                      {email && (
                        <div className="tooltipp">
                          <img
                            src={emailIcon}
                            alt="removeIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => email(row["id"])}
                          />
                          <span className="tooltiptext">Email</span>
                        </div>
                      )}
                      {payment && (
                        <div className="tooltipp">
                          <img
                            src={paymentIcon}
                            alt="payment Icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => payment(row["id"])}
                          />
                          <span className="tooltiptext">Payments</span>
                        </div>
                      )}
                      {notes && (
                        <div className="tooltipp">
                          <img
                            src={notesIcon}
                            alt="notesIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => notes(row)}
                          />
                          <span className="tooltiptext">Notes</span>
                        </div>
                      )}
                    </div>
                    <Dropdown className="d-flex justify-content-center d-lg-none">
                      <Dropdown.Toggle
                        variant="light"
                        className="border-0 p-0"
                        id="dropdown-basic"
                        style={{ background: "transparent" }}
                      >
                        <img src={ellipsis} alt="ellipsis" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className=" position-fixed rounded-0 border-0 shadow">
                        {actionButton &&
                          actionButton?.isShow(actionButtonVar) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                actionButton.onClick(row["id"]);
                              }}
                            >
                              {actionButton.label}
                            </Dropdown.Item>
                          )}
                        {ActiveDeactive &&
                          row["is_subscription_cancelled"] === 0 && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                ActiveDeactive.onClick(row);
                              }}
                            >
                              {ActiveDeactive.label(row)}
                            </Dropdown.Item>
                          )}
                        {toggleActionButton && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              toggleActionButton.onClick(row);
                            }}
                          >
                            {toggleActionButton.label(row)}
                          </Dropdown.Item>
                        )}
                        {duplicate && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => duplicate(row.id)}
                          >
                            Duplicate
                          </Dropdown.Item>
                        )}
                        {edit && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => edit(row["id"])}
                          >
                            Edit
                          </Dropdown.Item>
                        )}
                        {unarchive && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => unarchive(row.id)}
                          >
                            Unarchive
                          </Dropdown.Item>
                        )}
                        {archive && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => archive(row.id)}
                          >
                            Archive
                          </Dropdown.Item>
                        )}
                        {remove && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              setModalDeleteShow(true);
                              setId(row);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                        {print && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              print(row["id"]);
                            }}
                          >
                            Print
                          </Dropdown.Item>
                        )}
                        {view && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              view(row["id"]);
                            }}
                          >
                            View
                          </Dropdown.Item>
                        )}
                        {email && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              email(row["id"]);
                            }}
                          >
                            Email
                          </Dropdown.Item>
                        )}
                        {loginButton && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              loginButton.onClick(row["username"]);
                            }}
                          >
                            Login
                          </Dropdown.Item>
                        )}
                        {visitButton && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              visitButton.onClick(row["username"]);
                            }}
                          >
                            Visit
                          </Dropdown.Item>
                        )}

                        {payment && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              payment(row["id"]);
                            }}
                          >
                            Payments
                          </Dropdown.Item>
                        )}
                        {notes && (
                          <Dropdown.Item
                            className="purple"
                            onClick={() => {
                              notes(row);
                            }}
                          >
                            Purchase Order
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal
        show={modelDeleteShow}
        onHide={cancelConfirm}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img
              src={dangerline}
              alt="dangerline"
              style={{ position: "absolute", right: "auto", top: "2.2rem" }}
            />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">
              Do you really want to delete?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100" onClick={() => deleteConfirm(id)}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Archive Model */}

      <Modal
        show={modelArchiveShow}
        onHide={cancelConfirm}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img
              src={dangerline}
              alt="dangerline"
              style={{ position: "absolute", right: "auto", top: "2.2rem" }}
            />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">
              Do you really want to Archive?
            </span>
          </div>
          <div className="d-flex justify-content-center pb-4">
            <form
              autoComplete="new pasdsfas"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                type="password"
                placeholder="Master Password"
                value={archivePassword}
                onChange={(e) => {
                  setArchivePassword(e.target.value);
                }}
              />
            </form>
          </div>
          {archiveError.length > 0 ? (
            <Alert className="danger text-center p-2" variant="danger">
              {archiveError}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button>
            <Button
              className="w-100 rounded-3"
              onClick={() => archiveConfirm(id, archivePassword)}
              disabled={archivePassword.length === 0}
            >
              Archive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* UnArchive Model */}

      <Modal
        show={modelUnArchiveShow}
        onHide={cancelConfirm}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Unarchive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img
              src={dangerline}
              alt="dangerline"
              style={{ position: "absolute", right: "auto", top: "2.2rem" }}
            />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">
              Do you really want to Unarchive?
            </span>
          </div>
          <div className="d-flex justify-content-center pb-4">
            <form
              autoComplete="new pasdsfas"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                type="password"
                placeholder="Master Password"
                value={unarchivePassword}
                autoComplete={false}
                onChange={(e) => {
                  setUnarchivePassword(e.target.value);
                }}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button>
            <Button
              className="w-100 rounded-3"
              onClick={() => unarchiveConfirm(id, unarchivePassword)}
              disabled={unarchivePassword.length === 0}
            >
              Unarchive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomizeTableview;
