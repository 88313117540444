import { useState } from "react";
import { Accordion } from "react-bootstrap";
import DisplayAccordian from "./DisplayAccordian";

const ImageViewModel = ({ data, id }) => {
  const [display, setDisplay] = useState(0);
  const [open, setOpen] = useState();
  return (
    <Accordion>
      {data?.map((col, i) => (
        <DisplayAccordian
          key={i}
          data={col}
          id={id}
          index={i}
          display={display}
          setDisplay={setDisplay}
          open={open}
          setOpen={setOpen}
        />
      ))}
    </Accordion>
  );
};

export default ImageViewModel;
