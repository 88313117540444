import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import Markdown from "../../Components/Markdown/Markdown";
import AddForm from "../../Components/AddForm";
import SubmitWaiting from "../../Components/SubmitWaiting/SubmitWaiting";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import PopUpModal from "../../Components/PopUpModal";
import AddComponent from "../../Components/Component/AddComponent";
import InputControl from "../../Components/InputFields/InputControl";
import DropdownControl from "../../Components/InputFields/DropdownControl";
import { dateTime } from "../../Features/dateTime";

const AccessoriesFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, id } = useParams();

  const getComponentApi = useApi(api.getComponent);
  const componentApi = useApi(api.getComponentData);

  const [validated, setValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [modelName, setModelName] = useState("");
  const [launchPrice, setLaunchPrice] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [brand, setBrand] = useState();
  const [color, setColor] = useState([]);
  const [accessoryType, setAccessoryType] = useState();
  const [description, setDescription] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const [submiting, setSubmiting] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [failed, setFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [componentData, setComponentData] = useState(false);
  const [refresh, toggle] = useState(false);
  const [refreshDropdown, setRefreshDropdown] = useState("");
  const [isModalLoading, setIsModalLoading] = useState("");

  const getDropdownOptions = (component, filter) => {
    let timer = null;
    const comCall = (search) => {
      return componentApi.request(component, filter, true, undefined, undefined, search);
    };
    return (inputText) => {
      if (timer) {
        clearTimeout(timer);
      }
      return new Promise((resolve, reject) => {
        if (inputText.length > 0) {
          timer = setTimeout(() => {
            comCall(inputText)
              .then((data) => {
                const getData = data?.data?.success?.data.map(({ id, label }) => ({
                  value: id,
                  label: label,
                }));
                resolve(getData);
              })
              .catch((err) => {
                reject(err);
              });
          }, 200);
        } else {
          timer = null;
          comCall()
            .then((data) => {
              const getData = data?.data?.success?.data.map(({ id, label }) => ({
                value: id,
                label: label,
              }));
              resolve(getData);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    };
  };
  useEffect(() => {
    if (id) {
      axios.post(process.env.REACT_APP_URL_API_DATA + "/model/accessories/" + id).then((res) => {
        if (res.data?.code === 200) {
          setErrorMessage("");
          const resData = res?.data?.success?.data;
          setTitle(resData.title);
          setModelName(resData.model_name);
          setBrand({
            value: resData.brand_id,
            label: resData.brand_name,
          });
          setLaunchPrice(resData.launch_price ? +resData.launch_price : "");
          if (resData.release_date) {
            let objectDate = dateTime(resData.release_date);
            let day = objectDate.getDate();
            let month = objectDate.getMonth() + 1;
            let year = objectDate.getFullYear();
            setReleaseDate(`${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`);
          }
          setColor(
            resData.colors_available.map(({ id, label }) => ({
              value: id,
              label: label,
            })),
          );
          setAccessoryType({
            value: resData.at_id,
            label: resData.accessory_type,
          });
          setDescription(resData.description);
          setDescriptionText(resData.description);
        } else {
          setErrorMessage(res.data?.error?.message);
        }
      });
    }

    return () => {};
  }, [id]);

  useEffect(() => {
    setTitle(`${brand ? brand.label.trim() : ""} ${modelName.trim()} ${accessoryType ? accessoryType.label.trim() : ""}`);
  }, [modelName, brand, accessoryType]);

  const callForComponentData = async (table_name) => {
    setIsModalLoading(true);
    setShowAddComponentModal(true);
    const tableComponentResponse = await getComponentApi.request(table_name);
    if (tableComponentResponse?.data?.code === 200) {
      setComponentData(tableComponentResponse?.data?.success?.data);
      setRefreshDropdown(table_name);
    }
    setIsModalLoading(false);
  };

  useEffect(() => {
    setRefreshDropdown("");
  }, [refresh]);

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
      if (title.trim().length > 0 && modelName.trim().length > 0 && launchPrice?.toString().length > 0 && +launchPrice > 0 && releaseDate && descriptionText.trim().length > 0 && Object.keys(brand).length > 0 && Object.keys(color).length > 0 && Object.keys(accessoryType).length > 0) {
        setSubmiting(true);
        const obj = {
          model_title: title.trim(),
          model_name: modelName.trim(),
          release_date: releaseDate,
          launch_price: launchPrice,
          category_id: 6,
          category_name: "Accessories",
          brand_name: brand.label,
          brand_id: brand.value,
          colors_available: color.map((obj) => ({
            id: obj.value,
            label: obj.label,
          })),
          accessory_type: accessoryType.label,
          at_id: accessoryType.value,
          description: description,
        };
        let modelRes;
        if (mode === "edit" && id) {
          modelRes = await axios.patch(process.env.REACT_APP_URL_API_DATA + "/model/update/" + id, obj, { withCredentials: true });
        } else if (mode === "duplicate" && id) {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/duplicate/" + id, obj, { withCredentials: true });
        } else {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/add", obj, { withCredentials: true });
        }
        if (modelRes) {
          setSubmited(true);
          setTimeout(() => {
            setSubmiting(false);
            setSubmited(false);
            navigate("/accessories?page=" + location.state.page);
          }, 2000);
        }
      }
    } catch (err) {
      setFailed(true);
      setTimeout(() => {
        setSubmiting(false);
        setFailed(false);
      }, 2000);
    }
  };

  return (
    <>
      <SubmitWaiting submiting={submiting} submited={submited} failed={failed} />
      <AddForm>
        <div className="d-flex justify-content-start">
          <button className="backFormButton" onClick={() => navigate(-1)}>
            <span>Back</span>
          </button>
        </div>
        <div className="d-flex justify-content-center mx-4 mb-2">
          <h2 className="text-secondary text-capitalize">{mode} accessories</h2>
        </div>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row>
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              label={"title"}
              type={"text"}
              placeholder={"Enter Title"}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              disabled={true}
              required
              feedback={{ type: "invalid", message: "Please Enter the title." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"text"}
              label={"Model Name"}
              placeholder={"Enter Model Name"}
              value={modelName}
              important={true}
              onChange={(e) => {
                setModelName(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Model name." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"number"}
              label={"Launch Price"}
              placeholder={"Enter Launch Price"}
              value={launchPrice}
              important={true}
              onChange={(e) => {
                const format = /^[0-9]*$/;
                const [first, second] = e.target.value.split(".");
                let endResult;
                let firstPart;
                if (format.test(first)) {
                  if (first?.length < 8) {
                    firstPart = first;
                  } else {
                    firstPart = first.slice(0, 8);
                  }
                }
                if (e.target.value.includes(".")) {
                  if (second?.length > 0) {
                    if (format.test(second)) {
                      if (second?.length < 3) {
                        endResult = [firstPart, second].join(".");
                      } else {
                        endResult = [firstPart, second.slice(0, 2)].join(".");
                      }
                    }
                  } else {
                    endResult = firstPart + ".";
                  }
                } else {
                  if (firstPart && format.test(firstPart)) {
                    endResult = firstPart;
                  } else {
                    endResult = "";
                  }
                }
                setLaunchPrice(endResult);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Launch Price." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"date"}
              label={"Release Date"}
              placeholder={"Enter Release Date"}
              value={releaseDate}
              important={true}
              onChange={(e) => {
                setReleaseDate(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Release Date." }}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"brand" === refreshDropdown ? refreshDropdown : "brand-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("brand")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Brand"}
              important={true}
              cache={true}
              options={getDropdownOptions("brand", {
                is_accessory: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setBrand(e);
              }}
              value={brand}
              required={true}
              feedback={{ type: "invalid", message: "Please select brand." }}
              isInvalid={validated && !brand}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"color" === refreshDropdown ? refreshDropdown : "color-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("color")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"color"}
              important={true}
              cache={true}
              options={getDropdownOptions("color", {
                is_accessory: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              closeMenuOnSelect={false}
              onChange={(e) => {
                setColor(e);
              }}
              value={color}
              isMulti={true}
              required={true}
              feedback={{ type: "invalid", message: "Please select colors." }}
              isInvalid={validated && !color.length}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"accessory_type" === refreshDropdown ? refreshDropdown : "accessory-type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("accessory_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Accessory Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("accessory_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setAccessoryType(e);
              }}
              value={accessoryType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Accessory Type." }}
              isInvalid={validated && !accessoryType}
            />
            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="description">
              <Form.Label className="asterik-label">Description:</Form.Label>
              <Markdown value={description} setValue={setDescription} setText={setDescriptionText} placeholder="Enter Description (5,000 Characters)" />
              <Form.Control
                hidden
                as="textarea"
                placeholder="Enter Description (5,000 Characters)"
                value={descriptionText.trim()}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxLength={5000}
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter the description.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="submit-button">
              <Form.Control type="button" value={mode === "edit" ? "Edit" : "Add"} onClick={submitForm} className="btn btn-primary text-capitalize" />
            </Form.Group>
          </Row>
        </Form>
      </AddForm>
      <PopUpModal size="md" title={`Add ${componentData.name}`} show={showAddComponentModal} onHide={() => setShowAddComponentModal(false)} isLoading={isModalLoading}>
        <AddComponent close={() => setShowAddComponentModal(false)} toggle={toggle} fieldData={componentData} />
      </PopUpModal>
    </>
  );
};

export default AccessoriesFormPage;
