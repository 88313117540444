import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import style from "../../styles/Pagination.module.css";

const PaginationBar = ({ noOfPages, currentPage, changePage }) => {
  const [tempPageInput, setTempPageInput] = useState("");
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const changePageHandle = (i) => {
    changePage(+i);
  };

  const createPaginationItem = (i) => {
    return (
      <Pagination.Item key={i} active={i === currentPage} onClick={() => changePageHandle(i)}>
        {i}
      </Pagination.Item>
    );
  };

  useEffect(() => {
    let row = [];
    if (noOfPages < 20) {
      for (var i = 1; i <= noOfPages; i++) {
        row.push(createPaginationItem(i));
      }
    } else {
      row.push(createPaginationItem(1));
      if (currentPage - 2 > 1) {
        row.push(<Pagination.Ellipsis />);
      }
      let midpoint = currentPage - 1;
      if (midpoint <= 1) {
        midpoint = 2;
      } else if (midpoint + 4 >= noOfPages) {
        midpoint = noOfPages - 5;
      }
      for (let i = midpoint; i <= midpoint + 4; i++) {
        row.push(createPaginationItem(i));
      }
      if (currentPage + 4 < noOfPages) {
        row.push(<Pagination.Ellipsis />);
      }
      row.push(createPaginationItem(noOfPages));
    }
    setPages(row);
  }, [noOfPages, currentPage]);

  const directToPage = (p) => {
    if (+p > 0 && +p <= noOfPages) {
      changePageHandle(p);
      setTempPageInput("");
    }
  };

  const tempPageInputHandle = (e) => {
    console.log(e.target.value, "change");
    if (+e.target.value > 0 && +e.target.value <= noOfPages) {
      setTempPageInput(e.target.value);
    } else {
      setTempPageInput("");
    }
  };

  return (
    <div className="d-flex justify-content-around align-items-center">
      <div>
        <input
          type="number"
          placeholder="Go to page"
          value={tempPageInput}
          onChange={() => {}}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              directToPage(tempPageInput);
            } else {
              const re = /^[0-9\b]+$/;
              if (re.test(e.key)) {
                let temp = tempPageInput + e.key;
                if (+temp > 0 && +temp <= noOfPages) {
                  setTempPageInput(temp);
                } else {
                  setTempPageInput("");
                }
              } else {
                setTempPageInput("");
              }
            }
          }}
        />
        <button onClick={() => directToPage(tempPageInput)}>Go</button>
      </div>
      <Pagination className={`mb-0 justify-content-center align-items-center ${style.paginationItems} `}>
        <Pagination.Prev disabled={currentPage === 1} onClick={() => changePageHandle(currentPage - 1)} />
        {pages}
        <Pagination.Next disabled={currentPage === noOfPages} onClick={() => changePageHandle(currentPage + 1)} />
      </Pagination>
    </div>
  );
};

export default PaginationBar;
