import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import customloader from "../../assets/images/RollingLoader.gif";

export default function Metadata({ show, close, isLoading, isSaving, state, setState, onSubmit }) {
  return (
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={close} fullscreen="sm-down" backdrop="static">
      <Modal.Header className="py-2" closeButton>
        <Modal.Title>Metadata</Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px",
            backgroundColor: "#F5F6FA",
          }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <>
          <Modal.Body className="py-3 px-5">
            <Form noValidate onSubmit={onSubmit}>
              <fieldset disabled={isSaving}>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" placeholder="Enter Title" name="title" value={state.meta_title} maxLength={200} onChange={(e) => setState({ ...state, meta_title: e.target.value })} required />
                  <Form.Control.Feedback type="invalid">Title is required.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" placeholder="Enter Description" name="description" value={state.meta_description} maxLength={500} onChange={(e) => setState({ ...state, meta_description: e.target.value })} />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>Keywords</Form.Label>
                  <Form.Control as="textarea" placeholder="Enter Keywords" name="keywords" value={state.meta_keywords} maxLength={2000} onChange={(e) => setState({ ...state, meta_keywords: e.target.value })} />
                </Form.Group>
              </fieldset>
            </Form>
          </Modal.Body>
        </>
      )}
      <Modal.Footer className="border-0">
        <Button variant="outline-secondary" onClick={close} disabled={isSaving}>
          Close
        </Button>
        <Button onClick={onSubmit} disabled={isLoading || isSaving}>
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
