import React from "react";
import { Modal } from "react-bootstrap";
import ViewAccessories from "./ViewAccessories";
import ViewDesktopComputer from "./ViewDesktopComputer";
import ViewLaptop from "./ViewLaptop";
import ViewTab from "./ViewTablet";
import ViewLED from "./ViewTvMonitor";
import ViewMobile from "./ViewMobile";

function ModelViewModal({ show, onHide, modelTitle, id }) {
  return (
    <Modal size="lg" show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modelTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modelTitle === "Accessory" ? (
          <ViewAccessories id={id} />
        ) : modelTitle === "Desktop Computer" ? (
          <ViewDesktopComputer id={id} />
        ) : modelTitle === "Laptop" ? (
          <ViewLaptop id={id} />
        ) : modelTitle === "Mobile" ? (
          <ViewMobile id={id} />
        ) : modelTitle === "Tab" ? (
          <ViewTab id={id} />
        ) : modelTitle === "TV / Monitor" ? (
          <ViewLED id={id} />
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModelViewModal;
