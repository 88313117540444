import React from "react";
import { useState } from "react";
import Topbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar/Sidebar";
import style from "./../styles/Sidebar.module.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DashboardLayout = () => {
  const [isSandwichOpen, setIsSandwichOpen] = useState(false);
  const toggle = () => {
    setIsSandwichOpen((prev) => !prev);
  };
  return (
    <div className={`d-flex flex-column ${style.dashboardLayout}`}>
      <Topbar toggle={toggle} isSandwichOpen={isSandwichOpen} />
      <div className={`fixed-top ${style.sidebarLayout}`} style={{ zIndex: "2" }}>
        <Sidebar setIsSandwichOpen={setIsSandwichOpen} isSandwichOpen={isSandwichOpen} toggle={toggle} />
      </div>
      <div className={`mt-5 ${style.content} ${isSandwichOpen ? style.contentClose : ""}`}>
        <Outlet />
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover />
    </div>
  );
};

export default DashboardLayout;
