import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_DATA,
  // baseURL: "http://192.168.3.82:5010",
});
const imageApi = axios.create({
  baseURL: process.env.REACT_APP_URL_IMAGE_CDN,
});
const config = {
  headers: {
    Authorization: localStorage.getItem("refreshToken"),
  },
};

//Component---------- START -------------

export function getAllComponent() {
  return api.get(`/components`, { withCredentials: true });
}
export function getComponent(table) {
  return api.get(`/components/${table}`, { withCredentials: true });
}
export function Colors(id) {
  return api.get(`/model/colors/${id}`, { withCredentials: true });
}
export function ImagesBycolor(id, color) {
  return imageApi.get(`/data/get/${id}/${color}`, { withCredentials: true });
}
export function getComponentData(
  table,
  filters,
  dropdown = false,
  page,
  size,
  search = ""
) {
  const obj = JSON.stringify(filters);
  const endpoint = `/component/${table}${
    page && size ? `?page=${page}&size=${size}` : ""
  }`;
  return api.get(endpoint, {
    withCredentials: true,
    headers: { search, filters: obj, dropdown },
  });
}

export function getComponentNoOfPages(table, size, search, filters) {
  const obj = JSON.stringify(filters);
  return api.get(`/component/no-of-pages/${table}?size=${size}`, {
    withCredentials: true,
    headers: { search, filters: obj },
  });
}

export function getComponentById(table, id) {
  return api.get(`/component/${table}/${id}`, { withCredentials: true });
}

// Component---------- END---------------
