import React from "react";
import { Button } from "react-bootstrap";

function ModelMetaExcelActions({ title = "Metadata", setShowExcelExportModal = () => {}, setShowImportExcelModal = () => {} }) {
  return (
    <div className="d-flex justify-content-end align-items-center w-100 pt-2 pe-4">
      <div className="d-flex gap-2 p-2 border rounded">
        <div className="fs-4">
          <strong>{title}:</strong>
        </div>
        <Button
          onClick={() => {
            setShowExcelExportModal(true);
          }}
        >
          <strong>Export Excel</strong>
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setShowImportExcelModal(true);
          }}
        >
          <strong>Import Excel</strong>
        </Button>
      </div>
    </div>
  );
}

export default ModelMetaExcelActions;
