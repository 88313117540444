import { wait } from '@testing-library/user-event/dist/utils';
import React  from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import "../styles/404.css"

import style from "../styles/RanIntoProblem.module.css"

const RanIntoProblem = () => {
    const [perc,setPerc] = useState(0)
    
    useEffect(()=>{
        let interv;
        if(perc === 0){
            interv = setInterval(()=>{
                setPerc(perv=>(perv + Math.floor(Math.random() * 4)) % 100)
            },200)
        }else if(perc > 96 && perc < 100){
            clearInterval(interv)
            window.location.reload(false);
        }
    },[perc])
    return (
        <div className={style.body}>
            <div className={style.wind} style={{display: "inline"}}>
                <p className={style.emoticon}>:(</p>
                <p className={style.text}>This Page ran into a problem and needs to Refresh. We're just collecting some error info, and then we'll Refresh for you. ({perc}% complete)</p>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100&amp;display=swap" rel="stylesheet"></link>
                <button onClick={()=>{window.location.reload(false);}}>Reload Page</button>
            </div>
        </div>
    );
}
 
export default RanIntoProblem;