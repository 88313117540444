import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import inputStyle from "./imageUploader.module.css";
import { faClose, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";

import arrayMove from "array-move";

import { Draggable } from "react-drag-reorder";
import axios from "axios";

const NUMBER_OF_IMAGES = 5;

const Image = ({ src, onDelete, primary, isWorking }) => {
  return (
    <>
      <div className={inputStyle.image} style={{ overflow: "hidden" }}>
        {primary && (
          <span className="tag">
            <FontAwesomeIcon icon={faStar} />
          </span>
        )}
        {isWorking && (
          <FontAwesomeIcon
            className="icon"
            icon={faClose}
            onClick={() => onDelete(src)}
          />
        )}
        <div className="img">
          <img src={src.preview} alt="" width="100%" height="auto" />
        </div>
      </div>
    </>
  );
};

const RenderPhotos = ({ source, setSource, onDelete, isWorking }) => {
  const [dragKey, setDragKey] = useState(1);
  useEffect(() => {
    setDragKey((prev) => prev + 1);
  }, [source]);
  const handlePosChange = (currentPos, newPos) => {
    setSource(arrayMove(source, currentPos, newPos));
  };
  
  return (
    <>
      <div className="row justify-content-center">
        {isWorking ? (
          <Draggable key={dragKey} onPosChange={handlePosChange}>
            {source.map((photo, index) => {
              return (
                <div
                  key={index}
                  className={`${inputStyle.minWidth} col-lg-4 col-md-6 col-sm-12`}
                >
                  <Image
                    src={photo}
                    onDelete={onDelete}
                    primary={index === 0}
                    isWorking={isWorking}
                  />
                </div>
              );
            })}
          </Draggable>
        ) : (
          <>
            {source.map((photo, index) => {
              return (
                <div
                  key={index}
                  className={`${inputStyle.minWidth1} col-lg-2 col-md-3 col-sm-12`}
                >
                  <Image
                    src={photo}
                    onDelete={onDelete}
                    primary={index === 0}
                    isWorking={isWorking}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

const ImageUploader = forwardRef(
  ({ selectedFiles, setSelectedFiles, setDeletedImages, isWorking, allowedImages = NUMBER_OF_IMAGES }, ref) => {
    const [noOfImageError, setNoOfImageError] = useState(false);
    const [sizeOfImageError, setSizeOfImageError] = useState(false);

    const { getRootProps, getInputProps, open } = useDropzone({
      accept: {
        "image/webp": [".webp"],
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
      },
      onDrop: (acceptedFiles, fileRejections) => {
        setSizeOfImageError(false);
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setSizeOfImageError(true);
            }
          });
        });
        const filesArray = acceptedFiles.map((file) => ({
          preview: URL.createObjectURL(file),
          data: file,
          type: "file",
        }));
        if (selectedFiles.length + filesArray.length < allowedImages + 1) {
          setSelectedFiles((prevImage) => [...prevImage, ...filesArray]);
          setNoOfImageError(false);
        } else {
          setNoOfImageError(true);
        }
        acceptedFiles.map((file) => URL.revokeObjectURL(file));
      },
      // maxFiles:10,
      maxSize: 2621440,
    });

    const onDelete = (photo) => {
      setSelectedFiles((prevImage) => prevImage.filter((img) => img !== photo));
      if (photo.type === "url") {
        setDeletedImages((prev) => [...prev, photo.preview.substring(photo.preview.indexOf('images/'))]);
      }
      setNoOfImageError(false);
      setSizeOfImageError(false);
    };

    useImperativeHandle(ref, () => ({
      uploadingImages: () => {
        return new Promise((resolve, reject) => {
          if (selectedFiles.length > 0) {
            const formData = new FormData();
            selectedFiles.map((file) => {
              formData.append("files", file.data, file.data.name);
            });
            axios
              .post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images`, formData, {
                headers: {
                  Accept: "application/json",
                  "content-type": "multipart/form-data",
                },
              })
              .then(({ data }) => {
                resolve(data.files);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
          }
        });
      },
    }));

    return (
      <>
        <Form.Group className={inputStyle.section}>
          <RenderPhotos
            source={selectedFiles}
            setSource={setSelectedFiles}
            onDelete={onDelete}
            getInputProps={getInputProps}
            isWorking={isWorking}
          />
          <Form.Label
            {...getRootProps({
              onClick: (e) => {
                e.preventDefault();
              },
            })}
            style={selectedFiles.length > 0 ? { display: "none" } : {}}
          >
            <strong>Choose images</strong> or drag here
            <Form.Control {...getInputProps()} />
          </Form.Label>
          {isWorking && (
            <>
              <hr />

              {sizeOfImageError && (
                <span style={{ color: "red" }}>
                  Image Size must be less than 2.5 MBs.
                </span>
              )}
              <br />
              {noOfImageError ? (
                <span style={{ color: "red" }}>
                  You can upload a maximum of {allowedImages} Image(s)
                </span>
              ) : (
                <span>{selectedFiles.length} Images Added</span>
              )}

              <br />
              {selectedFiles.length < allowedImages && (
                <Button
                  style={{ background: "#6c757d" }}
                  variant="secondary"
                  onClick={open}
                >
                  Add Images
                </Button>
              )}
            </>
          )}
        </Form.Group>
      </>
    );
  }
);

export default ImageUploader;
