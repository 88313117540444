import style from "./../../styles/Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const NavOption = ({
  setIsSandwichOpen,
  isSandwichOpen,
  title,
  icon,
  path,
  identifier,
  isSubNav,
  active,
  setActive,
  isOpen,
  setIsOpen,
  subnavigation,
}) => {
  return (
    <div className={`${style.Sidebartooltip} d-flex align-items-center`}>
      <NavLink
        to={path}
        className={`${style.sideLink} my-1 d-block w-100`}
        onClick={() => {
          if (window.innerWidth < 920) {
            //  console.log("asdf")
            setIsSandwichOpen((prev) => !prev);
          }
          setActive(identifier);
          if (isOpen && identifier === active) {
            // console.log(window.innerWidth)
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
        style={() =>
          active === identifier
            ? {
                backgroundColor: "rgba(241, 244, 250, 1)",
                color: "rgba(89, 50, 234, 1)",
                textDecoration: "none",
                marginRight: "3px",
              }
            : { color: "#545e6f", textDecoration: "none", marginRight: "3px" }
        }
      >
        <div
          className={`d-flex  ${
            subnavigation && "ms-4 "
          } ${"gap-2 justify-content-between"} align-items-center`}
        >
          <div className="d-flex align-items-center gap-2">
            {icon}
            {title}
            {/* {!isSandwichOpen && title} */}
          </div>
        </div>
        {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>}
      </NavLink>
      {isSubNav && (
        <FontAwesomeIcon
          onClick={() => {
            setActive(identifier);
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
          style={{ paddingRight: "8px" }}
          icon={isOpen ? faChevronUp : faChevronDown}
        />
      )}
    </div>
  );
};

export default NavOption;
