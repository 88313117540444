import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaginationBar from "../../Components/PaginationBar/PaginationBar";

//New Implementation----------------Start-----------------
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/404";
import ComponentPageView from "../../Components/Component/Component";

//New Implementation-----------------End------------------

const ComponentPage = () => {
  const { component } = useParams();
  const componentApi = useApi(api.getComponent);

  const [isLoading, setIsLoading] = useState(true);
  const [isPageFound, setIsPageFound] = useState(false);

  const [componentData, setComponentData] = useState(null);

  useEffect(() => {
    async function getComponent() {
      try {
        setIsLoading(true);
        const res = await componentApi.request(component);
        if (res) {
          setIsPageFound(true);
          setIsLoading(false);
          setComponentData(res.data.success.data);
        }
      } catch (error) {
        setIsPageFound(false);
      }
    }
    getComponent();
  }, [component]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isPageFound ? (
        <ComponentPageView componentData={componentData} />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default ComponentPage;
