import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaginationBar from "../Components/PaginationBar/PaginationBar";
import CustomizeTableview from "../Components/TableView/CustomizeTableview";

const DELAY_TIME = 100;

const Requests = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataRefresh, toggle] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");
  const currentSearch = useRef();
  currentSearch.current = search;

  const [searchParams] = useSearchParams();

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/requests");
  };

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      axios
        .get(process.env.REACT_APP_URL_API_DATA + "/model-request/no-of-pages?size=" + sizeOfPages, {
          withCredentials: true,
        })
        .then((res) => {
          const no_of_pages = res?.data?.success?.data?.no_of_pages;
          if (no_of_pages) {
            if (no_of_pages === 0) {
              setNoRecord(true);
              setCurrentPage(1);
              setNoOfPages(0);
            } else {
              setNoRecord(false);
              setNoOfPages(no_of_pages);
              if (currentPage > no_of_pages) {
                setCurrentPage(no_of_pages);
              }
            }
          }
        });
    }, DELAY_TIME);

    return () => {
      clearTimeout(pageTimeout);
    };
  }, [sizeOfPages, dataRefresh, data, navigate, currentPage, searchParams, search]);

  useEffect(() => {
    const dataTimeout = setTimeout(() => {
      if (!noRecord) {
        axios
          .get(process.env.REACT_APP_URL_API_DATA + "/model-request", {
            withCredentials: true,
          })
          .then((res) => {
            setData(res?.data?.success?.data);
          });
      } else {
        setData([]);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(dataTimeout);
      if (currentSearch.current !== search) {
        setCurrentPage(1);
      }
    };
  }, [dataRefresh, currentPage, sizeOfPages, search, navigate, noRecord]);

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };

  const rejectHandle = (id) => {
    axios
      .patch(
        process.env.REACT_APP_URL_API_DATA + "/model-request/reject/" + id,
        {},
        {
          withCredentials: true,
        },
      )
      .then((abc) => {
        toggle((prev) => !prev);
      });
  };
  const resolvedHandle = (id) => {
    axios
      .patch(
        process.env.REACT_APP_URL_API_DATA + "/model-request/resolve/" + id,
        {},
        {
          withCredentials: true,
        },
      )
      .then((abc) => {
        toggle((prev) => !prev);
      });
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <h2 className="text-secondary">Requests</h2>
      </div>
      <div className="d-flex justify-content-between mx-4">
        <input type="search" placeholder="Search" className="px-3 rounded" value={search} onChange={handleSearchInput} />
      </div>

      {noRecord ? (
        <div style={{ padding: "50px 0px" }}>
          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
              textAlign: "center",
              padding: "35px",
              fontWeight: "bolder",
              borderRadius: "10px",
            }}
          >
            Record Not Found
          </div>
        </div>
      ) : (
        <div className="p-4">
          <CustomizeTableview
            data={data}
            columnNotShow={["id", "is_added", "comment"]}
            datetimeColumn={["created_at"]}
            status={{
              column: "is_added",
              style: (value) => {
                if (value === 1) {
                  return "text-success";
                } else if (value === 0) {
                  return "text-danger fst-italic";
                } else {
                  return "text-danger";
                }
              },
              format: (value) => {
                if (value === 1) {
                  return "RESOLVED";
                } else if (value === 0) {
                  return "PENDING";
                } else {
                  return "REJECTED";
                }
              },
            }}
            actionButton={{
              label: "Resolved",
              onClick: resolvedHandle,
              variant: "primary",
              style: { padding: "8px", fontSize: "12px" },
              column: "is_added",
              isShow: (value) => {
                if (value > 0) {
                  return false;
                } else {
                  return true;
                }
              },
            }}
            actionButton1={{
              label: "Reject",
              onClick: rejectHandle,
              variant: "danger",
              style: { padding: "8px", fontSize: "12px" },
              column: "is_added",
              isShow: (value) => {
                if (value > 0) {
                  return false;
                } else {
                  return true;
                }
              },
            }}
          />
          <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
        </div>
      )}
    </>
  );
};

export default Requests;
