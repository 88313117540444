import style from "./../../styles/Sidebar.module.css";
import profile from "./../../assets/images/profile.png";
import SideNavData from "./SideNavData";
import MenuItem from "./MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./../../Features/authSlice";
import Cookies from "../../Features/Cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTableList } from "@fortawesome/free-solid-svg-icons";
import signOut from "./../../assets/svg/signout.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import axios from "axios";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";

const Sidebar = ({ setIsSandwichOpen, isSandwichOpen, toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userName1 = user.user_full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
  const getdata = useApi(api.getAllComponent);
  const navigate = useNavigate();

  const [data, setdata] = useState(SideNavData);
  const [subNav, setSubNav] = useState([]);

  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    navigate("/login");
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await getdata.request();
        setSubNav(
          data?.data?.success?.data?.map((table) => {
            return {
              subnavigation: true,
              title: table.name,
              path: `/components/${table.associated_table}`,
              icon: <FontAwesomeIcon icon={faTableList} />,
            };
          }),
        );
      } catch (error) {}
    };
    fetchdata();
  }, []);
  useEffect(() => {
    setdata((prev) => {
      return prev.map((table) => {
        if (table.title === "Components") {
          return { ...table, subNav: subNav };
        } else {
          return table;
        }
      });
    });
  }, [subNav]);

  return (
    <div className={`bg-white shadowMain ${style.sidebar} ${!isSandwichOpen ? "" : style.sandwichOpen}`}>
      <div className={`justify-content-between justify-content-lg-center py-3 px-2 ${style.infoDiv}`}>
        <div className={`${style.userInfo}`}>
          <div className="d-flex align-items-center">
            <div className={`me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>{userName1}</div>
            {/* <img width='52px' height='52px' className='rounded-circle' src={profile} alt="profile" /> */}
          </div>
          <div>
            <p className="fw-bold mb-0 text-capitalize">{user && user.user_full_name}</p>
            <span className="fw-light text-capitalize">{user && user.user_type}</span>
          </div>
        </div>
        <div role="button" className={`text-white ${style.closeCTA}`} onClick={() => toggle()}>
          Close <FontAwesomeIcon icon={faClose} />
        </div>
      </div>
      <div className={` d-flex flex-column justify-content-between ${isSandwichOpen ? "align-items-center" : "align-items-start"} ${style.sidebarItems}`}>
        <div className={`${style.scroll} w-100`}>
          <Menu setIsSandwichOpen={setIsSandwichOpen} navData={data} isSandwichOpen={isSandwichOpen} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
