import React from "react";
import { utils, write, writeFile } from "xlsx";
import { saveAs } from "file-saver";

export default function ExcelButton({ api, format, error, filename, responseObject = null, children, onComplete = null }) {
  function requestAllHistory() {
    api()
      .then((data) => {
        if (responseObject) {
          const dataForDownload = responseObject(data)?.map((list) => {
            return format(list);
          });
          const aoa = dataForDownload.map((list) => {
            return Object.values(list);
          });
          aoa.unshift(Object.keys(dataForDownload[0]));
          const wb = utils.book_new();
          const ws = utils.aoa_to_sheet(aoa);
          utils.book_append_sheet(wb, ws, "Sheet1");
          const wbArray = write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([wbArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," });
          saveAs(blob, `${filename}.xlsx`);
          if (onComplete) {
            onComplete();
          }
        } else {
          const dataForDownload = data.data.map((list) => {
            return format(list);
          });
          const aoa = dataForDownload.map((list) => {
            return Object.values(list);
          });
          aoa.unshift(Object.keys(dataForDownload[0]));
          const wb = utils.book_new();
          const ws = utils.aoa_to_sheet(aoa);
          utils.book_append_sheet(wb, ws, "Sheet1");
          const wbArray = write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([wbArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," });
          saveAs(blob, `${filename}.xlsx`);
          if (onComplete) {
            onComplete();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (error) {
          error("Error in loading data");
        }
        // if (error(err)) {
        //   setDownloadData([]);
        // }
      });
  }
  return (
    <>
      <button type="button" className="btn-outline-primary btn" onClick={() => requestAllHistory()}>
        <span>{children ? children : "Download as Excel"}</span>
        <span className="download"></span>
      </button>
    </>
  );
}
