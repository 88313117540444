import React from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";

function DropdownControl({ label, type, closeMenuOnSelect = true, important, placeholder, id, value, onChange, onKeyDown, onClick, disabled, required, feedback, options, cache, onInputChange, isSearchable, defaultValue, defaultInputValue, isMulti, isClearable, isLoading, ...props }) {
  const { as, lg, md, sm, className, ...controlProps } = props;
  return (
    <Form.Group {...(as ? { as: as } : {})} {...(lg ? { lg: lg } : {})} {...(md ? { md: md } : {})} {...(sm ? { sm: sm } : {})} className={`mb-3 ${className ? className : ""}`} controlId={label}>
      <Form.Label className={`text-capitalize ${important ? "asterik-label" : ""}`}>{label}:</Form.Label>
      <Form.Control
        {...controlProps}
        as={Dropdown}
        defaultValue={defaultValue}
        backspaceRemovesValue
        defaultInputValue={defaultInputValue}
        isMulti={isMulti}
        isDisabled={disabled}
        menuPlacement="bottom"
        loadOptions={options}
        closeMenuOnSelect={closeMenuOnSelect}
        defaultOptions
        isClearable={isClearable}
        value={value}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onClick={onClick}
        onChange={onChange}
        isLoading={isLoading}
        isSearchable={isSearchable}
        onInputChange={onInputChange}
        {...(cache ? { cacheOptions: true } : {})}
        {...(required ? { required: true } : {})}
      />
      {feedback ? <Form.Control.Feedback type={feedback.type}>{feedback.message}</Form.Control.Feedback> : <></>}
    </Form.Group>
  );
}

const Dropdown = (props) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? "#5932EA" : "#80858C",
      // background: state.isSelected ? "#F5F6FA" : "#fff",
      // padding: 10,
      background: state.isDisabled ? "#E8E8E8" : "",
    }),
    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   width: "100%",
    //   maxWidth: "100%",
    // }),
    singleValue: (provided, state) => ({
      color: "#000",
      marginTop: "-30px",
    }),
  };
  return <AsyncSelect {...props} classNamePrefix="react-select-container" styles={customStyles} />;
};

export default DropdownControl;
