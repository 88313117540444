import React, { useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import ImageUploader from "./ImageUploader/ImageUploader";
import * as api from "../api/getapi";
import useApi from "../Hooks/useApi";
import * as postApi from "../api/postapi";
import { useEffect } from "react";

function UploadImageByColor({ data, index, id }) {
  const uploadImage = useApi(postApi.updateImage);
  const imagesByColor = useApi(api.ImagesBycolor);

  const [status, setStatus] = useState("pending");
  const [isWorking, setIsWorking] = useState(true);
  const [refill, setRefill] = useState(true);
  const imageComponent = useRef();
  const [images, setImages] = useState({ old: [], current: [] });
  const [deletedImages, setDeletedImages] = useState([]);
  
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const res = await imagesByColor.request(id, data.id);
        if (res.data.length > 0) {
          const retImage = res.data.map((image) => {
            const obj = {
              preview: process.env.REACT_APP_URL_S3 + "/" + image,
              data: image,
              type: "url",
            };
            return obj;
          });

          setImages({ old: retImage, current: retImage });
          if (retImage.length > 0) {
            setStatus("uploaded");
            setIsWorking(false);
          }
        } else {
          setStatus("empty");
        }
      } catch (error) {}
    };
    fetchImages();
  }, [id, data, refill]);
  const submit = async (e) => {
    try {
      if (images?.current?.length > 0) {
        setStatus("pending");
        const formData = new FormData();
        let imgObj = {};
        let count = 0;
        images?.current?.map((file, index) => {
          if (file.type === "file") {
            formData.append("files", file.data, file.data.name);
            imgObj["img" + index] = { index: count };
            count = count + 1;
          } else {
            imgObj["img" + index] = { url: file.data };
            // imgObj["img" + index] = { url: file.preview };
          }
        });
        imgObj["color_id"] = data.id;
        imgObj["color_name"] = data.label;
        formData.append("images", JSON.stringify(imgObj));
               formData.append("deleted",  JSON.stringify(deletedImages));
        const res = await uploadImage.request(id, formData);
        if (res) {
          setStatus("uploaded");
          setIsWorking(false);
          setRefill((prev) => !prev);
        } else {
          setStatus("empty");
          setIsWorking(true);
  }
      }
    } catch (error) {}
  };

  const selectImageHandle = (callback) => {
    if (typeof callback === "function") {
      setImages((prev) => ({ ...prev, current: callback(prev?.current) }));
    } else {
      setImages((prev) => ({ ...prev, current: callback }));
    }
  };

  return (
    <div className="container">
      <Accordion.Item eventKey={index}>
        <Accordion.Header className="">
          <span className="d-flex flex-row justify-content-between align-items-center w-100">
            <span style={{ color: "black", fontWeight: "550" }}>{data?.label || "Color"}</span>
            {status === "empty" ? (
              <span className="me-2">❌</span>
            ) : status === "pending" ? (
              <div className="col-3 ms-3 d-flex justify-content-end me-5">
                <div className="snippet" data-title="dot-flashing-black">
                  <div className="stage">
                    <div className="dot-flashing-black"></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <span className="me-2">✅</span>
              </>
            )}
          </span>
        </Accordion.Header>
        <Accordion.Body className="d-flex flex-column align-items-end">
          <ImageUploader ref={imageComponent} selectedFiles={images?.current} setSelectedFiles={selectImageHandle} setDeletedImages={setDeletedImages} isWorking={isWorking} />
          <div className="d-flex ">
            {!isWorking && (
              <button
                className="btn btn-primary "
                type="submit"
                onClick={(e) => {
                  setIsWorking(true);
                  setStatus("edit");
                }}
              >
                Edit
              </button>
            )}
            {isWorking && images?.current?.length > 0 && status === "edit" && (
              <button
                className="btn btn-danger "
                type="submit"
                onClick={(e) => {
                  setIsWorking(false);
                  setStatus("uploaded");
                  setImages((prev) => ({
                    ...prev,
                    current: prev?.old,
                  }));
                }}
              >
                Cancel Edit
              </button>
            )}
            <button className="btn btn-primary mx-2" type="submit" onClick={(e) => submit(e)} disabled={status === "uploaded"}>
              {status === "uploaded" ? "Uploaded" : "Submit"}
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
}

export default UploadImageByColor;
