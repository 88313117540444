import { useEffect, useState } from "react";
const Home = () => {

    return ( <>
    <div className="text-center">
        <h1>Welcome to TechBazaar</h1>
        <h3>Data Modules</h3>
        </div>
    </> );
}
 
export default Home;